<template>
  <div class="fall">
    <ul v-if="list">
      <li v-for="item in list" :key="item.id" :class="item.className" :style="`width: ${item.width}px; left: ${item.left}px;`">
        <img :src="item.img" alt="">
      </li>
    </ul>
    <!-- <img class="fireworks" src="https://ossgw.alicdn.com/alp/1612643859787-750-1624.gif" alt=""> -->
  </div>
</template>

<script>
export default {
  name: 'Fall',
  data() {
    return {
      list: [],
      count: 1,
      imgs: [
        'https://res.xiaoxxx.com/urlGather/20230221c981e7e2a52ca600469431.png',
        // 'https://res.xiaoxxx.com/urlGather/2023022116f9358f787c00d2481153.png',
        'https://res.xiaoxxx.com/urlGather/20230221ec61f7bfe1534e9c672779.png',
        'https://res.xiaoxxx.com/urlGather/20230221a08e6100b4fad6ad672209.png',
        'https://res.xiaoxxx.com/urlGather/202302213f7d14d74e670099976842.png'
        // require('../assets/s1.png'),
        // require('../assets/s2.png'),
        // require('../assets/s3.png'),
      ],
      className: [
        'down1',
        'down2',
        'down3',
        'down4',
      ]
    }
  },
  created() {
    let wang=setInterval(() => {
      for(let i = 0; i < 2; i++) {
        let width = Math.floor(Math.random() * 30) + 20
        let left = Math.floor(Math.random() * (document.body.clientWidth - width))
        let className =  this.className[Math.floor(Math.random() * this.className.length)]
        this.list.push({
          id: this.count,
          img: this.imgs[Math.floor(Math.random() * this.imgs.length)],
          width: width,
          left: left,
          className: className
        })
        setTimeout(() => {
          this.list.shift()
        }, 3000)
      this.count += 1
      }
    }, 100);
    setTimeout(()=>{
      clearInterval(wang);
    },1800)
    
  }
}
</script>

<style scoped>
.fall {
  width: 100%;
  height: 100vh;
  max-height: 18rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
}
.fall ul {
  width: 100%;
  height: 100%;
  position: relative;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); */
}
.fall li {
  position: absolute;
  /* animation: down 1s forwards; */
}
.fall ul img{
  width: 100%;
}
.down1 {
  animation: down 1.7s ease-out forwards;
}
.down2 {
  animation: down 2.2s ease-out forwards;
}
.down3 {
  animation: down 1.5s ease-out forwards;
}
.down4 {
  animation: down 1.9s ease-out forwards;
}
@keyframes down {
  0% {
    opacity: 1;
    transform: translateY(-1rem) rotate(90deg);
  }
  70% {
    opacity: 1;
    /* transform: translateY(90vh) rotate(20deg); */
  }
  100% {
    opacity: 0;
    transform: translateY(90vh) rotate(0deg);
  }
}

.fireworks {
  width: 100%;
  position: absolute;
  top: -2rem;
  left: 0;
}
</style>