<template>
  <div class="certificate">
    <!-- 试卷回顾列表 -->
    <div class="opener-box" v-if="page">
      <img src="../assets/gbgz.png" alt="" class="gbtc"  @click="closes"/>
      <div class="reviewBox">
        <div class="reviewOption" v-for="(val, i) in sjtm" :key="i">
          <div style="display: flex;align-items:center;">
            <div>第{{ i + 1 }}题 ({{
              val.topic.type == "1"
              ? "单选题"
              : val.topic.type == "2"
                ? "多选题"
                : "判断题"
            }})</div>
            <div class="review_icon" v-if="val.correct != 1"></div>
            <div class="review_icons" v-if="val.correct == 1"></div>
          </div>
          <div style="display: flex;align-items:center;" @click="chank(val, i)">
            <div style="color: #1E58D3;">点击查看</div>
            <div class="review_icon2"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 试卷回顾详情 -->
    <div class="tmk" v-if="!page">
      <div class="tmkz">
        <div class="nrk">
          <div class="btk">
            <img src="../assets/tmk.png" alt="" class="tmsxk" />
            <div class="tmsl">{{ xb + 1 }}/{{ sjtm.length }}</div>
            <div class="tmlx">
              ({{
                sjtm[xb].topic.type == "1"
                ? "单选题"
                : sjtm[xb].topic.type == "2"
                  ? "多选题"
                  : "判断题"
              }})
            </div>


          </div>
          <div class="tmm">
            {{ sjtm[xb].topic.title }}
          </div>
          <!-- 单选题 -->

          <div class="dxt" v-if="sjtm[xb].topic.type == '1'">
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[0].option) != -1 }"
              v-if="sjtm[xb].topic.option[0] != null">
              <div class="xxm">A.</div>
              {{ sjtm[xb].topic.option[0].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[1].option) != -1 }"
              v-if="sjtm[xb].topic.option[1] != null">
              <div class="xxm">B.</div>
              {{ sjtm[xb].topic.option[1].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[2].option) != -1 }"
              v-if="sjtm[xb].topic.option[2] != null">
              <div class="xxm">C.</div>
              {{ sjtm[xb].topic.option[2].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[3].option) != -1 }"
              v-if="sjtm[xb].topic.option[3] != null">
              <div class="xxm">D.</div>
              {{ sjtm[xb].topic.option[3].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[4].option) != -1 }"
              v-if="sjtm[xb].topic.option[4] != null">
              <div class="xxm">E.</div>
              {{ sjtm[xb].topic.option[4].answer }}
            </div>
          </div>
          <!-- 多选题 -->
          <div class="dxts" v-if="sjtm[xb].topic.type == '2'">
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[0].option) != -1 }"
              v-if="sjtm[xb].topic.option[0] != null">
              <div class="xxm">A.</div>
              {{ sjtm[xb].topic.option[0].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[1].option) != -1 }"
              v-if="sjtm[xb].topic.option[1] != null">
              <div class="xxm">B.</div>
              {{ sjtm[xb].topic.option[1].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[2].option) != -1 }"
              v-if="sjtm[xb].topic.option[2] != null">
              <div class="xxm">C.</div>
              {{ sjtm[xb].topic.option[2].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[3].option) != -1 }"
              v-if="sjtm[xb].topic.option[3] != null">
              <div class="xxm">D.</div>
              {{ sjtm[xb].topic.option[3].answer }}
            </div>
            <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].topic.option[4].option) != -1 }"
              v-if="sjtm[xb].topic.option[4] != null">
              <div class="xxm">E.</div>
              {{ sjtm[xb].topic.option[4].answer }}
            </div>
          </div>
          <!-- 判断题 -->
          <div class="pdt" v-if="sjtm[xb].topic.type == '3'">
            <div id="xx1" :class="{ xx1: xzd.indexOf(sjtm[xb].topic.option[0].option) != -1 }"
              v-if="sjtm[xb].topic.option[0] != null">
              A. {{ sjtm[xb].topic.option[0].answer }}
            </div>
            <div id="xx2" :class="{ xx1: xzd.indexOf(sjtm[xb].topic.option[1].option) != -1 }"
              v-if="sjtm[xb].topic.option[1] != null">
              B.{{ sjtm[xb].topic.option[1].answer }}
            </div>
          </div>
          <div class="rightck">
            <img src="../assets/d.png" alt="" class="rightc" v-if="rights == 1" />
            <img src="../assets/x.png" alt="" class="rightc" v-if="rights == 2" />
          </div>
          <!-- 正确答案 -->
          <div class="jiexi" v-if="rights != 0 && xzd.length!=0">
            <span style="font-weight: bold">正确答案：</span>{{ opes }}
          </div>
          <div class="jiexis" v-if="rights != 0 &&xzd.length!=0 && sjtm[xb].analysis_status==2">
            <span style="font-weight: bold">解析：</span>{{ sjtm[xb].topic.analysis!=null&&sjtm[xb].topic.analysis!='null'?sjtm[xb].topic.analysis:'' }}
          </div>
          <div class="xybtn" @click="squestion" v-if="xb == sjtm.length - 1">上一题</div>
          <div class="xybtn" @click="xquestion" v-if="xb == 0">下一题</div>

          <div class="xybtns" v-if="xb != 0 && xb != sjtm.length - 1">
            <div class="" @click="squestion">上一题</div>
            <div class="" @click="xquestion">下一题</div>
          </div>
        </div>
        <div class="">

        </div>
      </div>
      <div class="record_btn" @click="page=!page">返回列表</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Review',

  props: {
    selected: {
      type: Array,
    default: () => []
    },
  },
  data() {
    return {
      opes: '',
      page: true,
      review_datas: null,
      xb: 2,
      xzd: [],
      rights: 1,
      sjtm:[],
    };
  },
  created() {
    this.sjtm= this.selected
    // console.log(this.sjtm)



  },
  mounted() {

  },
  watch: {

  },
  methods: {
    squestion() {
      this.chank(this.sjtm[this.xb - 1], this.xb - 1)
    },
    xquestion() {
      this.chank(this.sjtm[this.xb + 1], this.xb + 1)
    },
    chank(a, i) {
      this.page = false
      this.xb = i
      this.xzd = a.answer
      this.rights = a.correct
      this.opes=''
      if (this.sjtm[this.xb].topic.type == "1") {
        for (var x = 0; x < this.sjtm[this.xb].topic.option.length; x++) {
          if (this.sjtm[this.xb].topic.option[x].option == this.sjtm[this.xb].topic.true) {
            // console.log("aaaaaaaaaaa",x)
            if (x == 0) {
              this.opes = "A"

            }
            if (x == 1) {
              this.opes = "B"
            }
            if (x == 2) {
              this.opes = "C"
            }
            if (x == 3) {
              this.opes = "D"
            }
            if (x == 4) {
              this.opes = "E"
            }
          }
        }
      }
      if (this.sjtm[this.xb].topic.type == "2") {
           

        for (var s = 0; s < this.sjtm[this.xb].topic.option.length; s++) {
          if (this.sjtm[this.xb].topic.true.indexOf(this.sjtm[this.xb].topic.option[s].option) != -1) {
           
            if (s == 0) {
              this.opes += "A"

            }
            if (s == 1) {
              this.opes += "B"
            }
            if (s == 2) {
              this.opes += "C"
            }
            if (s == 3) {
              this.opes += "D"
            }
            if (s == 4) {
              this.opes += "E"
            }
          }
        }
      }
      if (this.sjtm[this.xb].topic.type == "3") {
        for (var a = 0; a < this.sjtm[this.xb].topic.option.length; a++) {
          if (this.sjtm[this.xb].topic.option[a].option == this.sjtm[this.xb].topic.true) {
            // console.log("aaaaaaaaaaa",x)
            if (a == 0) {
              this.opes = "A"

            }
            if (a == 1) {
              this.opes = "B"
            }
            if (a == 2) {
              this.opes = "C"
            }
            if (a == 3) {
              this.opes = "D"
            }
            if (a == 4) {
              this.opes = "E"
            }
          }
        }
      }
    },

    closes() {
      this.$emit('deletes', '')
      // this.generation=false
      // setTimeout(()=>{
      //   this.qiequ()
      // },100)

    },


  },
};
</script>
<style scoped>
.record_btn {
  width: 4.6rem;
  height: 0.8rem;
  background-color: #ff6a26;
  color: #fff;
  font-size: 0.3rem;
  border-radius: 0.4rem;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  line-height: 0.8rem;
  text-align: center;
  /* margin-top: 0.45rem; */
  bottom: -1.2rem;
}

.fenxiang img {
  height: 0.4rem;
  bottom: -0.07rem;
  position: relative;

}

.fenxiang {
  font-size: 0.35rem;
  color: #fff;
  right: 0.35rem;
  top: 0.4rem;
  position: absolute;
  z-index: 20;
  /* font-weight: bold; */
}

.jiexi {
  width: 4.7rem;
  /* min-height: 0.69rem; */
  /* background-color: #2a6af2; */
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  font-size: 0.3rem;
  text-align: justify;
}

.jiexis {
  width: 4.7rem;
  /* min-height: 0.69rem; */
  /* background-color: #2a6af2; */
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  font-size: 0.3rem;
  text-align: justify;
}

.remindtit {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(to right, #e97a2f, #9044d3);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* font-weight: bold; */
  /* color: red; */
}

.remind {
  width: 3.5rem;
  height: 0.8rem;
  /* background-color: rgba(0, 0, 0, 0.4); */
  text-align: center;
  position: absolute;
  top: 4.65rem;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.3rem;
  letter-spacing: 0.01rem;
}

.xiaoren {
  width: 2rem;
  top: 0.8rem;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  z-index: 2;
}

.fhbtns div {
  background-color: #2a6af2;
  border-radius: 0.5rem;

  width: 2.4rem;
  line-height: 0.9rem;
  color: rgb(255, 255, 255) !important;
}

.fhbtns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 5rem;
  height: 0.9rem;
  top: 5.55rem;
  font-size: 0.3rem;
  /* background-color: #2a6af2; */
  /* background-color: #969696; */

  /* border-radius: 0.5rem; */
  color: rgb(255, 255, 255);
}

.rightc {
  position: absolute;
  top: -1.2rem;
  /* transform: translateY(-50%); */
  z-index: 10;
  right: -0.6rem;
  opacity: 0.8;
}

.rightck {
  position: relative;
  width: 4.5rem;
  /* background-color: rebeccapurple; */
  height: 0.01rem;
  /* top: -0.5rem; */
}

.fhbtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.79rem;
  height: 0.9rem;
  top: 5.55rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
}

.dftit .fs {
  color: #ef842e;
  font-weight: bold;
}

.dftit {
  width: 6rem;
  text-align: center;
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.45rem;
  letter-spacing: 0.01rem;
}

.defen {
  position: absolute;
  width: 6.08rem;
  top: 2.2rem;
  left: 0;
}

.cjk {
  position: absolute;
  width: 6.08rem;
  height: 7.4rem;
  /* background-color: aqua; */
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.tcts {
  /* min-width: 3.6rem; */
  white-space: nowrap;
  font-size: 0.3rem;
  background-color: rgba(8, 8, 8, 0.8);
  color: #fff;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 0.5rem;
  transform: translate(-50%, -50%);
  padding: 0.08rem 0.2rem;
  border-radius: 0.15rem;
}

.tctsv {
  white-space: nowrap;
  font-size: 0.3rem;
  background-color: rgba(8, 8, 8, 0.6);
  color: #fff;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 1.2rem;
  transform: translate(-50%, -50%);
  padding: 0.1rem 0.2rem;
  border-radius: 0.15rem;
  opacity: 0.8;
}

.tctsv span {
  font-weight: bold;
  color: red;
}

.xx1 {
  background-color: #a1bdf9 !important;
  color: white !important;
}

.xx2 {
  background-color: #a1bdf9 !important;
  color: white !important;
}

.xx {
  background-color: #a1bdf9 !important;
  color: white !important;
}

#xx1 {
  font-size: 0.25rem;
  /* font-weight: bold; */
  position: absolute;
  left: 0rem;
  height: 0.69rem;
  width: 2.1rem;
  background-color: #e2ebfd;
  line-height: 0.69rem;
  border-radius: 0.3rem;
}

#xx2 {
  font-size: 0.25rem;
  /* font-weight: bold; */
  position: absolute;
  right: 0rem;
  height: 0.69rem;
  width: 2.1rem;
  background-color: #e2ebfd;
  line-height: 0.69rem;
  border-radius: 0.3rem;
}

.pdt {
  width: 4.7rem;
  height: 0.69rem;
  /* background-color: #2a6af2; */
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.xybtns {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 4.7rem;
  height: 0.9rem;
  font-size: 0.3rem;
  /* background-color: #dadada; */
  /* border-radius: 0.5rem; */
  color: rgb(255, 255, 255);
  /* line-height: 0.9rem; */
  letter-spacing: 0.02rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.xybtns div {
  width: 2.2rem;
  height: 0.8rem;
  background-color: #2a6af2;
  line-height: 0.8rem;
  border-radius: 0.4rem;
  margin-top: 0.05rem;
}

.xybtn {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 4.7rem;
  height: 0.9rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.xxm {
  position: absolute;
  left: 0.25rem;
  top: 0.2rem;
}

#xx {
  /* font-weight: bold; */
  position: relative;
  min-width: 3.71rem;
  font-size: 0.25rem;
  padding: 0.16rem 0.3rem;
  padding-left: 0.55rem;
  background-color: #e2ebfd;
  border-radius: 0.3rem;
  text-align: justify;
  margin-bottom: 0.3rem;
}

.tmm {
  width: 4.7rem;
  position: relative;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 0.3rem;
  font-weight: bold;
  text-align: justify;
}

.tmlx {
  position: absolute;
  bottom: 0.1rem;
  font-size: 0.26rem;
  right: 0;
  font-weight: bold;
  color: #595959;
}

.tmlxsa {
  position: absolute;
  bottom: 0.1rem;
  font-size: 0.26rem;
  left: 0.1rem;
  font-weight: bold;
  color: #595959;
}

.alarm {
  position: absolute;
  width: 0.45rem;
  bottom: 0.03rem;

  /* transform: translateY(-50%); */
  left: 3.46rem;
}

.alarmtit {
  position: absolute;
  bottom: 0.1rem;
  /* right: 0; */
  left: 3.9rem;
  font-size: 0.26rem;
  font-weight: bold;
}

.tmsl {
  font-size: 0.3rem;
  position: absolute;
  bottom: 0.08rem;
  font-weight: bold;
  color: #723426;
  left: 50%;
  transform: translate(-50%);
}

.tmsxk {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%);
  height: 0.53rem;
}

.btk {
  position: relative;
  height: 0.8rem;
  width: 4.7rem;
}

.nrk {
  position: relative;
  width: 4.7rem;
  /* background-color: #723426; */
  left: 50%;
  transform: translate(-50%);
}
.tmkz {
  position: relative;
  /* top: 0; */
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  /* background-color: rgb(255, 255, 255); */
  width: 6.08rem;
  /* height: 100%; */
  /* border-radius: 0.3rem; */
  max-height: 12rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.tmk {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  width: 6.08rem;
  border-radius: 0.3rem;
  max-height: 12rem;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}
</style>
<style  scoped>
.review_icon2 {
  width: 0.117rem;
  height: 0.195rem;
  background-color: red;
  background: url(../assets/r.png) no-repeat;
  background-size: cover;
  background-position: center center;
  margin-left: 0.05rem;
  position: relative;
  top: 0.01rem;
}

.review_icon {
  width: 0.3rem;
  height: 0.3rem;
  /* background-color: red; */
  background: url(../assets/cc.png) no-repeat;
  background-size: cover;
  background-position: center center;
  margin-left: 0.25rem;
  top: 0.01rem;

}

.review_icons {
  width: 0.3rem;
  height: 0.3rem;
  /* background-color: red; */
  background: url(../assets/dd.png) no-repeat;
  background-size: cover;
  background-position: center center;
  margin-left: 0.25rem;
  top: 0.01rem;

}

.reviewOption {
  width: 5rem;
  position: relative;
  height: 0.7rem;
  background-color: #F5F7FB;
  border-radius: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0rem 0.3rem;
  font-size: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewBox {
  width: 5.6rem;
  height: 9.65rem;
  /* background-color: rebeccapurple; */
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 1.05rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.gbtc {
  height: 0.53rem;
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
}

.opener-box {
  position: absolute;
  /* top: 1.6rem; */
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  /* transform: translate(-50%); */
  width: 6rem;
  height: 10.84rem;
  /* background-color: rgb(255, 225, 225); */
  background: url(../assets/hgbg.png) no-repeat;
  background-size: cover;
  background-position: center center;

}

.certificate {
  position: absolute;
  width: 100%;
  height: 100vh;
  max-height: 18rem;
  min-height: 13rem;
  max-width: 7.5rem;
  left: 0rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
</style>