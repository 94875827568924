<template>
  <div>
    <div class="mmm">
      {{time}}
    </div>
 </div>
</template>
<script>
export default {
 data () {
  return {
    time:"",
    mm:7200,
    dsq:null,
  }
 },
 created () {
  this.time=this.s_to_hs(this.mm)
  // this.dsq=setInterval(()=>{
  //   this.mm--
  //   if(this.mm==0){
  //     clearInterval(this.dsq)
  //     this.dsq=null
  //   }
  // },1000)
},
watch:{
mm:function(){
  this.time=this.s_to_hs(this.mm)
  if(this.mm==5){
    console.log("即将超时")
    
  }
},
},
 methods: {

s_to_hs(s){
    //计算分钟
    //算法：将秒数除以60，然后下舍入，既得到分钟数
    var h;
    h  =   Math.floor(s/60);
    //计算秒
    //算法：取得秒%60的余数，既得到秒数
    s  =   s%60;
    //将变量转换为字符串
    h    +=    '';
    s    +=    '';
    //如果只有一位数，前面增加一个0
    h  =   (h.length==1)?'0'+h:h;
    s  =   (s.length==1)?'0'+s:s;
    return h+':'+s;
}
  },
}
</script>

<style  scoped>
</style>
