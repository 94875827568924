<template>
    <div class="share-certificate">
        <div class="share-bg">
            <div class="share-box" id="jia">
               <img :src="ShareInformations.url" alt="" class="share-haibao">
               <div class="qrcode share-qrcode" id="share-qrcode" ref="qrCodeUrl"></div>
               <img :src="imgs" alt="" v-show="imgs!=''" class="share-biaomian">
            </div>
            <transition name="van-fade">
              <div class="share-changan" v-show="imgs!=''">长按保存，分享给你的好友吧</div>
            </transition>
            <img src="../assets/gb.png" alt="" class="share-gbBtn" @click="closeShare">
        </div>
    </div>
</template>
  <script>
  import html2canvas from "html2canvas";
  import QRCode from 'qrcodejs2'
  export default {
   name:'Share',
   props:{
    ShareInformation:{
          type:Object,
          default:{}
      },
   },
    data() {
      return {
        //   background:'',
        //   logo:'',
          ShareInformations:{},
        //   generation:false,//准备生成
        //   load:false,//图片加载状态
        //   fallShow:false,//下落元素
          imgs:'',
      };
    },
    created() {
      // console.log(this.ShareInformation)
      let wang=this.ShareInformation
      if(wang.url){
        
        // wang.url=wang.url.replace('http://','//')
        this.ShareInformations=wang
      }
  
    },
    mounted(){
        this.creatQrCode();
        this.loat()

    },
    watch: {
  
    },
    methods: {
      creatQrCode() {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: `https://answer.xiaoxxx.cn/#/?activity_id=${window.localStorage.getItem("coid")}`, // 需要转换为二维码的内容
            // text: `http://h5case6.xiaoxxx.cn/202302/Ant/dist/index.html#/?activity_id=${window.localStorage.getItem("coid")}`, // 需要转换为二维码的内容
            
            width: 70,
            height: 70,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
        // console.log(qrcode)
      },
      loat(){
        let $this=this
        
        // let image = new Image();
        // image.crossOrigin =''
        //   image.src = $this.ShareInformations.url;
        //   image.onload = () => {
            // $this.background=image.src
            // console.log('证书背景加载完了')
            
            
              setTimeout(()=>{
                $this.qiequs()
              },100)
          // };
      },
      closeShare(){
        this.$emit('gbshare', '')
        // this.generation=false
        // setTimeout(()=>{
        //   this.qiequ()
        // },100)
        
      },
      //截图------------------------------------------------------------------------------------------------------
      qiequs() {
        // console.log('图片正在生成中')
        var $this = this;
        html2canvas(document.getElementById("jia"), {
          width: document.querySelector("#jia").clientWidth,
          height: document.querySelector("#jia").clientHeight,
          backgroundColor: null, // null或transparent可将canvas背景设置为透明
        //   background:'red',
          scrollY: 0,
          scrollX: 0,
        //   allowTaint: true,  //开启跨域
          useCORS: true,
          scale: 2, //按比例增加分辨率 (2=双倍).
          dpi: window.devicePixelRatio * 4, //设备像素比
        }).then(function (canvas) {
          $this.imgs = canvas.toDataURL('image/png');
        //   console.log($this.imgs);
          // console.log('图片生成成功')
          
          // document.body.appendChild(canvas);
        });
      },
  
    },
  };
  </script>
  <style>
    #share-qrcode canvas{
      display: block !important;
    }
    #share-qrcode img{
      display: none !important;
    }
  </style>
  <style  scoped>
  .share-qrcode{
    position: absolute;
    bottom: 0.3rem;
    text-align: center;
    /* width: 100%; */
    left: calc(50% - 38px);
    padding: 6px;
    background-color: #fff;
    border-radius: 0.1rem;
    display: block;
  }
  .share-biaomian{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* background-color: aqua; */
    /* top: 1rem; */
  }
  .share-haibao{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .share-changan{
    position: absolute;
    top: 9.9rem;
    font-size: 0.3rem;
    color: #fff;
    text-align: center;
    width: 100%;
  }
  .share-gbBtn{
      width: 0.5rem;
      position: absolute;
      bottom: -1.5rem;
    transform: translate(-50%);
    background-size:cover;
  }
  .share-box {
    position: absolute;
    /* top: 1.6rem; */
    left: calc(50% - 3.19rem);
    /* transform: translate(-50%); */
    width: 6.38rem;
    height: 9.76rem;
    /* background-color: antiquewhite; */
  }
  .share-bg {
    position: absolute;
  width: 100%;
  height: 9.76rem;
  /* background-color: rgb(183, 91, 91); */
  transform: translate(-50%,-50%);
  top: 45%;
  left: 50%;
  
  }
  .share-certificate{
      position: absolute;
      width: 100%;
      height: 100vh;
      max-height: 18rem;
      min-height: 13rem;
      max-width: 7.5rem;
      left: 0rem;
      overflow: hidden;
      background-color: rgba(0,0,0,0.5);
      z-index: 10;
  }
  </style>