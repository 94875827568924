<template>
  <div class="bg">
    <div class="bgz">
      <!-- <img src="../assets/tit.png" alt="" class="tit" />
      <img src="../assets/logo.png" alt="" class="logo" /> -->
      <!-- <img src="../assets/dtbtn.png" alt="" class="dtbtn" /> -->
      <!-- <img src="../assets/phbbtn.png" alt="" class="phb" />
      <img src="../assets/gzbtn.png" alt="" class="gz" /> -->
    </div>
    <div class="mengc">
      <transition name="van-fade">
        <div class="tcts" v-if="xsyc">{{ tsnr }}</div>
      </transition>
      <transition name="van-fade">
        <div class="tctsv" v-if="xsycv"><span v-show="time_type == 2">本题</span>还有最后<span class="spans">{{ tsnrv }}</span>秒
        </div>
      </transition>
      <div class="tmk" v-if="dtmk == 1 && sjtm.length != 0" v-show="!review_show">
        <!-- 顶部总时间闹钟 -->
        <div class="total_alarm_clock" v-if="time_status == 2 && time_type == 1">
          <div>{{ ttmm }}</div>
        </div>
        <div class="tmkz">
          <div class="nrk">
            <img src="../assets/d.png" alt="" class="rightc" v-if="rights == 1" />
            <img src="../assets/x.png" alt="" class="rightc" v-if="rights == 2" />
            <div class="btk">
              <img src="../assets/tmk.png" alt="" class="tmsxk" />
              <div class="tmsl">{{ xb + 1 }}/{{ sjtm.length }}</div>
              <div class="tmlx" v-if="!(time_status == 2 && time_type == 2)">
                ({{
                  sjtm[xb].type == "1"
                  ? "单选题"
                  : sjtm[xb].type == "2"
                    ? "多选题"
                    : "判断题"
                }})
              </div>
              <div class="tmlxsa" v-if="time_status == 2 && time_type == 2">
                ({{
                  sjtm[xb].type == "1"
                  ? "单选题"
                  : sjtm[xb].type == "2"
                    ? "多选题"
                    : "判断题"
                }})
              </div>
              <img src="../assets/nz.png" alt="" class="alarm" v-if="time_status == 2 && time_type == 2" />
              <div class="alarmtit" v-if="time_status == 2 && time_type == 2">{{ ttmm_d }}</div>
            </div>
            <div class="tmm">
              {{ sjtm[xb].title }}
            </div>
            <!-- 单选题 -->

            <div class="dxt" v-if="sjtm[xb].type == '1'">
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[0].option) != -1 }" v-if="sjtm[xb].option[0] != null"
                @click="xzx(sjtm[xb].option[0].option)">
                <div class="xxm">A.</div>
                {{ sjtm[xb].option[0].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[1].option) != -1 }" v-if="sjtm[xb].option[1] != null"
                @click="xzx(sjtm[xb].option[1].option)">
                <div class="xxm">B.</div>
                {{ sjtm[xb].option[1].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[2].option) != -1 }" v-if="sjtm[xb].option[2] != null"
                @click="xzx(sjtm[xb].option[2].option)">
                <div class="xxm">C.</div>
                {{ sjtm[xb].option[2].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[3].option) != -1 }" v-if="sjtm[xb].option[3] != null"
                @click="xzx(sjtm[xb].option[3].option)">
                <div class="xxm">D.</div>
                {{ sjtm[xb].option[3].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[4].option) != -1 }" v-if="sjtm[xb].option[4] != null"
                @click="xzx(sjtm[xb].option[4].option)">
                <div class="xxm">E.</div>
                {{ sjtm[xb].option[4].answer }}
              </div>
            </div>
            <!-- 多选题 -->
            <div class="dxts" v-if="sjtm[xb].type == '2'">
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[0].option) != -1 }" v-if="sjtm[xb].option[0] != null"
                @click="xzx(sjtm[xb].option[0].option)">
                <div class="xxm">A.</div>
                {{ sjtm[xb].option[0].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[1].option) != -1 }" v-if="sjtm[xb].option[1] != null"
                @click="xzx(sjtm[xb].option[1].option)">
                <div class="xxm">B.</div>
                {{ sjtm[xb].option[1].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[2].option) != -1 }" v-if="sjtm[xb].option[2] != null"
                @click="xzx(sjtm[xb].option[2].option)">
                <div class="xxm">C.</div>
                {{ sjtm[xb].option[2].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[3].option) != -1 }" v-if="sjtm[xb].option[3] != null"
                @click="xzx(sjtm[xb].option[3].option)">
                <div class="xxm">D.</div>
                {{ sjtm[xb].option[3].answer }}
              </div>
              <div id="xx" :class="{ xx: xzd.indexOf(sjtm[xb].option[4].option) != -1 }" v-if="sjtm[xb].option[4] != null"
                @click="xzx(sjtm[xb].option[4].option)">
                <div class="xxm">E.</div>
                {{ sjtm[xb].option[4].answer }}
              </div>
            </div>
            <!-- 判断题 -->
            <div class="pdt" v-if="sjtm[xb].type == '3'">
              <div id="xx1" :class="{ xx1: xzd.indexOf(sjtm[xb].option[0].option) != -1 }"
                v-if="sjtm[xb].option[0] != null" @click="xzx(sjtm[xb].option[0].option)">
                A. {{ sjtm[xb].option[0].answer }}
              </div>
              <div id="xx2" :class="{ xx1: xzd.indexOf(sjtm[xb].option[1].option) != -1 }"
                v-if="sjtm[xb].option[1] != null" @click="xzx(sjtm[xb].option[1].option)">
                B.{{ sjtm[xb].option[1].answer }}
              </div>
            </div>
            <div class="xybtn" @click="xyt">
              {{ xb != sjtm.length - 1 ? "下一题" : "提交" }}
            </div>
          </div>
        </div>
      </div>
      <div class="cjk" v-if="dtmk == 2" v-show="!review_show">
        <img :src="imgbg" alt="" class="defen" />
        <img :src="imgsa" alt="" class="xiaoren" id="xiaorens" />
        <div class="dftit">
          恭喜你获得
          <span class="fs">{{ fens }}</span>
          分
        </div>
        <div class="remind" v-if="remind == 2">
          <span class="remindtit" v-if="remindnum <= fens">
            {{ remindtitj }}
          </span>
          <span class="remindtit" v-if="remindnum > fens">
            {{ remindtitb }}
          </span>
        </div>
        <div class="fhbtn" @click="fhsy" v-if="types == 0 && lucky != 2">
          返回首页
        </div>
        <div class="fhbtn" @click="luckyDraw" v-if="types == 0 && lucky == 2">
          去抽奖
        </div>
        <div class="fhbtns" v-if="types == 1">
          <div @click="fhsy" v-if="lucky != 2">返回首页</div>
          <div @click="luckyDraw" v-if="lucky == 2">去抽奖</div>
          <a :href="urls.draw_url">
            <div>{{ urls.draw_button }}</div>
          </a>
        </div>
        <div class="record_btn" v-if="review_type == 2" @click="review_show = !review_show">
          试卷回顾
        </div>
      </div>
    </div>
    <transition name="van-fade">
      <div class="fenxiang" v-if="btnShare && !showShare && !showZheng && !review_show" @click="fenxiang">
        <img src="../assets/fx.png" />
        <span>分享</span>
      </div>
    </transition>
    <transition name="van-fade">
      <ZhengShu :zheng="zheng" v-if="showZheng" @delete="onDeleteItem"></ZhengShu>
    </transition>
    <transition name="van-fade">
      <Fall v-if="fallShow"></Fall>
    </transition>
    <transition name="van-fade">
      <Share :ShareInformation="ShareInformation" v-if="showShare" @gbshare="onshare"></Share>
    </transition>
    <!-- 试卷回顾 -->
    <transition name="van-fade">
      <Review :selected="selected" v-if="review_show" @deletes="review_click"></Review>
    </transition>
  </div>
</template>
<script>
import ZhengShu from '../components/zhengshu.vue'
import Review from '../components/review.vue'
import Share from '../components/share.vue'
import Fall from '../components/Fall.vue'
export default {
  components: {
    ZhengShu,
    Share,
    Fall,
    Review,
  },
  data() {
    return {

      time_type: 1, //用户计时类型//1总时长 2单题时长
      danClock: null, //定时器闹钟
      time_dans: 0,//总时长赋值使用
      time_yong: 0,//用时
      ttmm_d: '',//展示使用


      review_show: false,//试卷回顾的展示
      selected: [],//选中的
      review_type: 1,




      fallShow: false,

      ShareInformation: {},//分享的相关信息
      showShare: false,//是否展示分享模块
      btnShare: false,//是否展示分享按钮

      zheng: {},//证书的相关信息
      showZheng: false,//是否展示证书模块


      pushUrl: "dty",
      remind: null,
      remindtitj: "",
      remindtitb: "",
      remindnum: null,

      dtmk: 1, //模块
      sjtm: [], //筛选出来的随机题库
      xsyc: false, //提示弹窗
      tsnr: "", //提示弹窗的文案
      xsycv: false, //提示弹窗
      tsnrv: "", //提示弹窗的文案
      imgsa: require("../assets/xr.png"),
      imgbg: require("../assets/xmk.png"),
      xb: 0, //题目下标
      xzd: [], //选中的答案
      dxdc: null, //多选的对错
      fens: 0, //分数
      ddsl: [], //答对题的id
      // ddsls: 0, //7答对题的数量

      rights: 0, //对错
      types: 0,
      urls: {},

      time_status: 1, //是否开启计时
      timezong: 0, //计时时秒
      timea: 0, //计时时秒

      ttmm: "", //倒计时分/秒
      clock: null, //定时器闹钟

      lucky: 1, //是否有资格抽奖

      frequency: 0,



      statistical_time: 0,//判断一下是否开启抽奖限制没开启的话统计用户答题时间
      statistical_name: null,//时间统计计时器


      reduction:false,

      // 证书及分享
    };
  },
  watch: {
    time_status: function () {
      if (this.time_status == 2) {
        if (this.time_type == 1) {
          this.ttmm = this.s_to_hs(this.timea);
          this.clock = setInterval(() => {
            this.timea--;
            if (this.timea == 0) {
              clearInterval(this.clock);
              this.clock = null;
              setTimeout(() => {
                if(this.reduction){
                  return
                }else{
                  this.reduction=true
                }
                this.dtmk = 2;
                window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)
                // return
                if ((this.zheng.score == 0 || this.zheng.score != null)) {
                  if (this.zheng.score <= this.fens) {
                    this.zheng.fens = this.fens
                    this.showZheng = true
                    // console.log('显示证书')
                    this.fallShow = true
                    setTimeout(() => {
                      this.fallShow = false
                    }, 3000)
                  }
                }
                if (this.ShareInformation.status) {
                  this.btnShare = true
                  // console.log('显示分享');
                }

                let timem
                if (this.time_status == 2&& this.time_type == 1) {
                  timem = this.timezong - this.timea
                } else {
                  clearInterval(this.statistical_name);
                  this.statistical_name = null
                  timem = this.statistical_time
                }
                // console.log(this.selected)

                this.$axios
                  .post(
                    "/submitResults",
                    `username=${window.localStorage.getItem("nameus")}&score=${this.fens
                    }&ids=${this.ddsl}&activity_id=${window.localStorage.getItem(
                      "coid"
                    )}&time=${timem
                    }&department_id=${window.localStorage.getItem(
                      "department_id"
                    )}&answered_num=${this.sjtm.length}&company_id=${window.localStorage.getItem("company_id")}`
                  )
                  .then((red) => {
                    // console.log(red);
                    if (red.data.code == 0) {
                      this.types = red.data.data.type;
                      this.urls = red.data.data.activity;
                      this.lucky = red.data.data.draw;



                      // console.log("提交成功");
                      // console.log(red.data.data.rankList)
                      // this.phbsj=red.data.data.rankList
                    } else {
                      // this.ts("网络异常成绩提交失败");
                    }
                  });
              }, 500);
            }
          }, 1000);
          this.$once('hook:beforeDestroy', () => {
            clearInterval(this.clock);
            clearInterval(this.danClock);
            clearInterval(this.statistical_name);
          })
        } else {
          // console.log('用户开启了单题计时')
          if (this.time_yong == 0) {
            // console.log(123654)
            // setTimeout(() => {
            this.xsycv = false;
            this.tsnrv = "";
            // }, 1)
          }
          // console.log(this.ttmm)
          this.questionTiming('s')
        }
      }

    },
    timea: function () {
      if (this.time_status == 2 && this.time_type == 1) {
        this.ttmm = this.s_to_hs(this.timea);
        if (this.timea <= 10) {
          this.tsz(`${this.timea}`);
          if (this.timea == 0) {
            setTimeout(() => {
              this.xsycv = false;
              this.tsnrv = "";
            }, 500);
          }
          // console.log("即将超时");
        }
        this.backups()
      }
    },
    time_yong: function () {
      if (this.time_status == 2 && this.time_type == 2) {
        if (this.time_yong <= 3) {
          this.tsz(`${this.time_yong}`);
          if (this.time_yong == 0) {
            setTimeout(() => {
              this.xsycv = false;
              this.tsnrv = "";
            }, 1)
          }
        }

        this.backups()
      }

    }
  },
  created() {
    // console.log(window.localStorage.getItem("department_id"))

    var sc = this.$route.params.na;
    if (sc != undefined) {
    } else {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    }
  },
  mounted() {
    if (
      window.localStorage.getItem("coid") == "null" ||
      window.localStorage.getItem("coid") == "" ||
      window.localStorage.getItem("coid") == undefined
    ) {
      this.$router.push({
        path: `/`,
      });
      return;
    }
    // console.log(window.localStorage.getItem("name"))
    if (
      window.localStorage.getItem("nameus") == null ||
      window.localStorage.getItem("nameus") == "" ||
      window.localStorage.getItem("nameus") == undefined
    ) {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    }
    if (this.$route.params.na == 1) {
      this.dysjhs();
    } else if (this.$route.params.na == 2) {
      let information = window.localStorage.getItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`);
      let inValue = {};
      if (information != null && information != undefined && information != "") {
        inValue = JSON.parse(information);

        this.time_type = inValue.time_type //用户计时类型//1总时长 2单题时长
        this.danClock = inValue.danClock //定时器闹钟
        this.time_dans = inValue.time_dans//总时长赋值使用
        this.time_yong = inValue.time_yong//用时
        this.ttmm_d = inValue.ttmm_d//展示使用


        this.review_show = inValue.review_show
        this.selected = inValue.selected
        this.review_type = inValue.review_type


        this.fallShow = inValue.fallShow
        this.ShareInformation = inValue.ShareInformation//分享的相关信息
        this.showShare = inValue.showShare//是否展示分享模块
        this.btnShare = inValue.btnShare//是否展示分享按钮

        this.zheng = inValue.zheng
        this.showZheng = inValue.showZheng

        this.pushUrl = inValue.pushUrl
        this.remind = inValue.remind
        this.remindtitj = inValue.remindtitj
        this.remindtitb = inValue.remindtitb
        this.remindnum = inValue.remindnum
        this.dtmk = inValue.dtmk
        this.sjtm = inValue.sjtm
        this.xsyc = inValue.xsyc
        this.tsnr = inValue.tsnr
        this.xsycv = inValue.xsycv
        this.tsnrv = inValue.tsnrv
        this.imgsa = inValue.imgsa
        this.imgbg = inValue.imgbg
        this.xb = inValue.xb
        this.xzd = inValue.xzd
        this.dxdc = inValue.dxdc
        this.fens = inValue.fens
        this.ddsl = inValue.ddsl
        this.rights = inValue.rights
        this.types = inValue.types
        this.urls = inValue.urls
        this.time_status = inValue.time_status
        this.timezong = inValue.timezong
        this.timea = inValue.timea
        this.ttmm = inValue.ttmm
        this.clock = inValue.clock
        this.lucky = inValue.lucky
        this.frequency = inValue.frequency

        this.statistical_time = inValue.statistical_time//判断一下是否开启抽奖限制没开启的话统计用户答题时间
        this.statistical_name = inValue.statistical_name//时间统计计时器

        this.reduction=inValue.reduction
        
        
        if (this.time_status == 2 && this.time_type == 1) {

        } else {
          this.statistical_method()
        }
      }
    }
  },
  methods: {
    // 单题时长方法
    questionTiming(h) {
      clearInterval(this.danClock);
          this.danClock = null;
      if (this.rights != 0) {
        return
      }
      // console.log('用户开启了单题计时')
      // time_type: 1, //用户计时类型//1总时长 2单题时长
      // danClock: null, //定时器闹钟
      // time_dans:0,//总时长赋值使用
      // time_yong:0,//用时
      // ttmm_d:'',//展示使用
      if (h == 's' && this.time_yong > 0) {

      } else {
        this.time_yong = this.time_dans
      }

      this.ttmm_d = this.s_to_hs(this.time_yong);
      this.danClock = setInterval(() => {
        this.time_yong--
        this.ttmm_d = this.s_to_hs(this.time_yong);
        if (this.time_yong == 0) {
          clearInterval(this.danClock);
          this.danClock = null;
          this.xyt('mandatory')
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
            clearInterval(this.clock);
            clearInterval(this.danClock);
            clearInterval(this.statistical_name);
          })
    },

    review_click() {
      this.review_show = false
      // console.log('关闭')
    },

    // 时间统计的方法
    statistical_method() {
      this.statistical_name = setInterval(() => {
        this.statistical_time = this.statistical_time + 1
        // console.log(this.statistical_time)
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
            clearInterval(this.clock);
            clearInterval(this.danClock);
            clearInterval(this.statistical_name);
          })
    },
    // 点击分享按钮
    fenxiang() {
      this.showShare = true
    },
    // 关闭海报分享
    onshare() {
      this.showShare = false
    },
    // 关闭证书
    onDeleteItem() {
      // console.log('gaunb')
      this.showZheng = false
      // console.log(childrenData)
      // this.childValue = childrenData
    },
    // 备份数据
    backups() {
      let information = {

        time_type: this.time_type, //用户计时类型//1总时长 2单题时长
        danClock: this.danClock, //定时器闹钟
        time_dans: this.time_dans,//总时长赋值使用
        time_yong: this.time_yong,//用时
        ttmm_d: this.ttmm_d,//展示使用


        review_show: this.review_show,//试卷回顾的展示
        selected: this.selected,//选中的
        review_type: this.review_type,

        fallShow: this.fallShow,
        ShareInformation: this.ShareInformation,//分享的相关信息
        showShare: this.showShare,//是否展示分享模块
        btnShare: this.btnShare,//是否展示分享按钮

        zheng: this.zheng,
        showZheng: this.showZheng,

        pushUrl: this.pushUrl,
        activity_id: window.localStorage.getItem("coid"),
        username: window.localStorage.getItem("nameus"),
        remind: this.remind,
        remindtitj: this.remindtitj,
        remindtitb: this.remindtitb,
        remindnum: this.remindnum,
        dtmk: this.dtmk,
        sjtm: this.sjtm,
        xsyc: this.xsyc,
        tsnr: this.tsnr,
        xsycv: this.xsycv,
        tsnrv: this.tsnrv,
        imgsa: this.imgsa,
        imgbg: this.imgbg,
        xb: this.xb,
        xzd: this.xzd,
        dxdc: this.dxdc,
        fens: this.fens,
        ddsl: this.ddsl,
        rights: this.rights,
        types: this.types,
        urls: this.urls,
        time_status: this.time_status,
        timezong: this.timezong,
        timea: this.timea,
        ttmm: this.ttmm,
        clock: this.clock,
        lucky: this.lucky,
        frequency: this.frequency,

        statistical_time: this.statistical_time,//判断一下是否开启抽奖限制没开启的话统计用户答题时间
        statistical_name: this.statistical_name,//时间统计计时器

        reduction:this.reduction,
      }
      window.localStorage.setItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`, JSON.stringify(information))

    },
    luckyDraw() {
      // console.log(1);
      if (this.urls.draw_type == 1) {
        this.$router.push({
          name: `draw`,
          params: {
            type: 1,
          },
        });
        // console.log("直接抽");
      }
      if (this.urls.draw_type == 2) {
        this.$router.push({
          name: `draw`,
          params: {
            type: 2,
          },
        });
        // console.log("转盘抽");
      }
    },
    s_to_hs(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
    // 点击切换下一题
    xyt(val) {
      clearInterval(this.danClock);
          this.danClock = null;
      this.backups()
      if (this.rights != 0) {
        return;
      }
      // console.log(1)
      // if(this.dtmk==2){
      //   return
      // }
      // console.log(this.xzd)
      if (this.xzd.length != 0) {
        if (this.sjtm[this.xb].type == "1") {
          if (this.sjtm[this.xb].true == this.xzd[0]) {
            this.fens += this.sjtm[this.xb].score;
            this.ddsl.push(this.sjtm[this.xb].id);
            // console.log(this.fens)
            // console.log(this.ddsl)
            this.rights = 1;
          } else {
            this.rights = 2;
          }
        }
        if (this.sjtm[this.xb].type == "2") {
          if (this.sjtm[this.xb].true.split("").length == this.xzd.length) {
            for (var i = 0; i < this.xzd.length; i++) {
              if (
                this.sjtm[this.xb].true.split("").indexOf(this.xzd[i]) == -1
              ) {
                this.dxdc = false;
              }
            }
            if (this.dxdc == null) {
              this.fens += this.sjtm[this.xb].score;
              this.ddsl.push(this.sjtm[this.xb].id);
              //     console.log(this.fens)
              // console.log(this.ddsl)
              this.rights = 1;
            } else {
              this.rights = 2;
            }
          } else {
            this.rights = 2;
          }
        }
        if (this.sjtm[this.xb].type == "3") {
          if (this.sjtm[this.xb].true == this.xzd[0]) {
            this.fens += this.sjtm[this.xb].score;
            this.ddsl.push(this.sjtm[this.xb].id);
            this.rights = 1;
          } else {
            this.rights = 2;
          }
        }
        setTimeout(() => {

          // this.selected.push({
          //   id:this.xb,
          //   answer:this.xzd,
          //   correct: this.rights,
          //   topic:this.sjtm[this.xb],
          // })
          this.selected[this.xb].answer = this.xzd
          this.selected[this.xb].correct = this.rights



          this.rights = 0;
          this.xzd = [];

          this.dxdc = null;
          if (this.xb != this.sjtm.length - 1) {
            this.xb += 1;
            if (this.time_status == 2 && this.time_type == 2) {
          this.questionTiming()
        }
            this.backups()
          } else {
            this.dtmk = 2;
            clearInterval(this.clock);
            this.clock = null;
            this.xsycv = false;
            this.tsnrv = "";
            // return
            // console.log()
            // console.log(this.zheng.score)
            if ((this.zheng.score == 0 || this.zheng.score != null)) {
              // console.log(this.zheng.score)
              if (this.zheng.score <= this.fens) {
                this.zheng.fens = this.fens
                this.showZheng = true
                // console.log('显示证书')
                this.fallShow = true
                setTimeout(() => {
                  this.fallShow = false
                }, 3000)
              }
            }
            if (this.ShareInformation.status) {
              this.btnShare = true
              // console.log('显示分享');
            }
            window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)

            let timem
                if (this.time_status == 2&& this.time_type == 1) {
                  timem = this.timezong - this.timea
                } else {
                  clearInterval(this.statistical_name);
                  this.statistical_name = null
                  timem = this.statistical_time
                }
                if(this.reduction){
                  return
                }else{
                  this.reduction=true
                }
            // console.log(this.selected)
            this.$axios
              .post(
                "/submitResults",
                `username=${window.localStorage.getItem("nameus")}&score=${this.fens
                }&ids=${this.ddsl}&activity_id=${window.localStorage.getItem(
                  "coid"
                )}&time=${timem
                }&department_id=${window.localStorage.getItem("department_id")}&answered_num=${this.sjtm.length}&company_id=${window.localStorage.getItem("company_id")}`
              )
              .then((red) => {
                // console.log(red);
                if (red.data.code == 0) {
                  this.types = red.data.data.type;
                  this.urls = red.data.data.activity;
                  this.lucky = red.data.data.draw;


                  // console.log("提交成功");
                  // console.log(red.data.data.rankList)
                  // this.phbsj=red.data.data.rankList
                } else {
                  // this.ts("网络异常成绩提交失败");
                }
              }).catch(err => {
                window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)
                this.$axios
                  .post(
                    "/submitResults",
                    `username=${window.localStorage.getItem("nameus")}&score=${this.fens
                    }&ids=${this.ddsl}&activity_id=${window.localStorage.getItem(
                      "coid"
                    )}&time=${timem
                    }&department_id=${window.localStorage.getItem("department_id")}&answered_num=${this.sjtm.length}&company_id=${window.localStorage.getItem("company_id")}`
                  )
                  .then((red) => {
                    // console.log(red);
                    if (red.data.code == 0) {
                      this.types = red.data.data.type;
                      this.urls = red.data.data.activity;
                      this.lucky = red.data.data.draw;

                      // console.log("提交成功");
                      // console.log(red.data.data.rankList)
                      // this.phbsj=red.data.data.rankList
                    } else {
                      if(red.data.code == -1){
                this.ts(red.data.message)
              }else{
                this.ts("网络异常成绩提交失败");
              }
                      // this.ts("网络异常成绩提交失败");
                    }
                  })
              });;
          }
        }, 800);
      } else {
        if (val == 'mandatory') {
          if (this.sjtm[this.xb].type == "1") {
            for (var x = 0; x < this.sjtm[this.xb].option.length; x++) {
              if (this.sjtm[this.xb].option[x].option == this.sjtm[this.xb].true) {
                // console.log("aaaaaaaaaaa",x)
                if (x == 0) {
                  this.opes = "A"

                }
                if (x == 1) {
                  this.opes = "B"
                }
                if (x == 2) {
                  this.opes = "C"
                }
                if (x == 3) {
                  this.opes = "D"
                }
                if (x == 4) {
                  this.opes = "E"
                }
              }
            }
          }
          if (this.sjtm[this.xb].type == "2") {
            for (var s = 0; s < this.sjtm[this.xb].option.length; s++) {
              if (this.sjtm[this.xb].true.indexOf(this.sjtm[this.xb].option[s].option) != -1) {
                if (s == 0) {
                  this.opes += "A"

                }
                if (s == 1) {
                  this.opes += "B"
                }
                if (s == 2) {
                  this.opes += "C"
                }
                if (s == 3) {
                  this.opes += "D"
                }
                if (s == 4) {
                  this.opes += "E"
                }
              }
            }
          }
          if (this.sjtm[this.xb].type == "3") {
            for (var a = 0; a < this.sjtm[this.xb].option.length; a++) {
              if (this.sjtm[this.xb].option[a].option == this.sjtm[this.xb].true) {
                // console.log("aaaaaaaaaaa",x)
                if (a == 0) {
                  this.opes = "A"

                }
                if (a == 1) {
                  this.opes = "B"
                }
                if (a == 2) {
                  this.opes = "C"
                }
                if (a == 3) {
                  this.opes = "D"
                }
                if (a == 4) {
                  this.opes = "E"
                }
              }
            }
          }
          // console.log('时间到了')
          this.rights = 2;
          setTimeout(() => {

            // this.selected.push({
            //   id:this.xb,
            //   answer:this.xzd,
            //   correct: this.rights,
            //   topic:this.sjtm[this.xb],
            // })
            this.selected[this.xb].answer = this.xzd
            this.selected[this.xb].correct = this.rights



            this.rights = 0;
            this.xzd = [];

            this.dxdc = null;
            if (this.xb != this.sjtm.length - 1) {
              this.xb += 1;
              if (this.time_status == 2 && this.time_type == 2) {
          this.questionTiming()
        }
              this.backups()
            } else {
              this.dtmk = 2;
              clearInterval(this.clock);
              this.clock = null;
              this.xsycv = false;
              this.tsnrv = "";
              // return
              // console.log()
              // console.log(this.zheng.score)
              if ((this.zheng.score == 0 || this.zheng.score != null)) {
                // console.log(this.zheng.score)
                if (this.zheng.score <= this.fens) {
                  this.zheng.fens = this.fens
                  this.showZheng = true
                  // console.log('显示证书')
                  this.fallShow = true
                  setTimeout(() => {
                    this.fallShow = false
                  }, 3000)
                }
              }
              if (this.ShareInformation.status) {
                this.btnShare = true
                // console.log('显示分享');
              }
              window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)

              let timem
                if (this.time_status == 2&& this.time_type == 1) {
                  timem = this.timezong - this.timea
                } else {
                  clearInterval(this.statistical_name);
                  this.statistical_name = null
                  timem = this.statistical_time
                }
                if(this.reduction){
                  return
                }else{
                  this.reduction=true
                }
              // console.log(this.selected)
              this.$axios
                .post(
                  "/submitResults",
                  `username=${window.localStorage.getItem("nameus")}&score=${this.fens
                  }&ids=${this.ddsl}&activity_id=${window.localStorage.getItem(
                    "coid"
                  )}&time=${timem
                  }&department_id=${window.localStorage.getItem("department_id")}&answered_num=${this.sjtm.length}&company_id=${window.localStorage.getItem("company_id")}`
                )
                .then((red) => {
                  // console.log(red);
                  if (red.data.code == 0) {
                    this.types = red.data.data.type;
                    this.urls = red.data.data.activity;
                    this.lucky = red.data.data.draw;


                    // console.log("提交成功");
                    // console.log(red.data.data.rankList)
                    // this.phbsj=red.data.data.rankList
                  } else {
                    // this.ts("网络异常成绩提交失败");
                  }
                }).catch(err => {
                  window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)
                  this.$axios
                    .post(
                      "/submitResults",
                      `username=${window.localStorage.getItem("nameus")}&score=${this.fens
                      }&ids=${this.ddsl}&activity_id=${window.localStorage.getItem(
                        "coid"
                      )}&time=${timem
                      }&department_id=${window.localStorage.getItem("department_id")}&answered_num=${this.sjtm.length}&company_id=${window.localStorage.getItem("company_id")}`
                    )
                    .then((red) => {
                      // console.log(red);
                      if (red.data.code == 0) {
                        this.types = red.data.data.type;
                        this.urls = red.data.data.activity;
                        this.lucky = red.data.data.draw;

                        // console.log("提交成功");
                        // console.log(red.data.data.rankList)
                        // this.phbsj=red.data.data.rankList
                      } else {
                        if(red.data.code == -1){
                this.ts(red.data.message)
              }else{
                this.ts("网络异常成绩提交失败");
              }
                        // this.ts("网络异常成绩提交失败");
                      }
                    })
                });;
            }
          }, 800);
        } else {
          this.ts("您还没选择呢");
        }
      }

    },
    //选中项
    xzx(val) {
      // console.log(val)
      if (this.sjtm[this.xb].type == "1") {
        this.xzd = [val];
      }
      if (this.sjtm[this.xb].type == "2") {
        if (this.xzd.indexOf(val) != -1) {
          this.xzd.splice(this.xzd.indexOf(val), 1);
        } else {
          this.xzd.push(val);
        }
      }
      if (this.sjtm[this.xb].type == "3") {
        this.xzd = [val];
      }
      this.backups()
    },
    //调用随机题目函数，
    dysjhs() {
      this.$axios
        .post(
          "/getQuestionLists",
          `activity_id=${window.localStorage.getItem(
            "coid"
          )}&username=${window.localStorage.getItem("nameus")}`
        )
        .then((red) => {
          // console.log(red);
          if (red.data.code == 0) {
            // console.log("获取题目成功");

            this.sjtm = red.data.data.list;
            for (var s = 0; s < this.sjtm.length; s++) {
              this.selected.push({
                id: s,
                analysis_status: red.data.data.activity.analysis_status,
                answer: [],
                correct: 2,
                topic: this.sjtm[s],
              })
            }
            // return
            if (
              red.data.data.img &&
              red.data.data.img.ip_img != "" &&
              red.data.data.img.ip_img != null
            ) {
              this.imgsa = red.data.data.img.ip_img;
            }
            if (
              red.data.data.img &&
              red.data.data.img.ending_img != "" &&
              red.data.data.img.ending_img != null &&
              red.data.data.img.ending_img != "null"
            ) {
              this.imgbg = red.data.data.img.ending_img;
            }
            // console.log(red,"wa")

            this.timezong = red.data.data.activity.time;
            this.timea = red.data.data.activity.time;
            this.time_dans = red.data.data.activity.time;
            this.time_type = red.data.data.activity.time_type


            this.time_status = red.data.data.activity.time_status;
            this.remind = red.data.data.activity.prompt_status;
            this.remindtitj = red.data.data.activity.prompt_arrive;
            this.remindtitb = red.data.data.activity.prompt_not;
            this.remindnum = red.data.data.activity.prompt_score;
            this.review_type = red.data.data.activity.review_type

            if (this.time_status == 2 && this.time_type == 1) {

            } else {
              this.statistical_method()
            }

            if (red.data.data.certificate) {
              if (red.data.data.certificate.status == 2) {
                this.zheng = red.data.data.certificate
                // this.zheng.fens=this.fens
                if (red.data.data.activity.name_status == 2) {
                  this.zheng.name_status = window.localStorage.getItem("nameus")
                } else {
                  this.zheng.name_status = ''
                }
                this.zheng.logo = this.zheng.logo.replace('http://', '//')
                this.zheng.background = this.zheng.background.replace('http://', '//')
                // console.log( this.zheng,111111111);
                let image = new Image();
                image.crossOrigin = ''
                // console.log($this.zhengs,'王佳乐')
                image.src = this.zheng.background;
                image.onload = () => {
                  // console.log('证书背景加载');
                }
                let image1 = new Image();
                image1.crossOrigin = ''
                // console.log($this.zhengs,'王佳乐')
                image1.src = this.zheng.logo;
                image1.onload = () => {
                  // console.log('logo加载');
                }
              }
            }
            if (red.data.data.poster) {
              if (red.data.data.poster.status == 2) {
                // this.btnShare=true
                this.ShareInformation = red.data.data.poster
                this.ShareInformation.url = this.ShareInformation.url.replace('http://', '//')
                let image2 = new Image();
                image2.crossOrigin = ''
                // console.log($this.zhengs,'王佳乐')
                image2.src = this.ShareInformation.url;
                image2.onload = () => {
                  // console.log('分享海报加载');
                }
              }
            }


            // console.log(red.data.data.rankList)
            // this.phbsj=red.data.data.rankList
            this.backups()

          } else {
            this.ts(red.data.message);
            setTimeout(() => {
              this.$router.push({
                path: `/?activity_id=${window.localStorage.getItem("coid")}`,
              });
            }, 1500);


          }
        });

      // this.sjtm = this.tkz;
    },
    // 获取随机题目的函数
    sjhs(arr, count) {
      var shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },

    fhsy() {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    },

    ts(i) {
      this.xsyc = true;
      this.tsnr = i;
      setTimeout(() => {
        this.xsyc = false;
        this.tsnr = "";
      }, 2000);
    },
    tsz(i) {
      this.xsycv = true;
      this.tsnrv = i;
      // setTimeout(() => {

      // }, 2000);
    },
  },
};
</script>
<style scoped>
.total_alarm_clock {
  position: absolute;
  top: -0.95rem;
  width: 1.6rem;
  height: 0.71rem;
  background: url(../assets/znz.png) no-repeat;
  background-size: cover;
  background-position: center center;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.3rem;
  line-height: 0.71rem;
  font-weight: bold;
  text-align: center;
  padding-left: 0.4rem;
}



.record_btn {
  width: 4.6rem;
  height: 0.8rem;
  background-color: #ff6a26;
  color: #fff;
  font-size: 0.3rem;
  border-radius: 0.4rem;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  line-height: 0.8rem;
  text-align: center;
  /* margin-top: 0.45rem; */
  bottom: -1.2rem;
}

.fenxiang img {
  height: 0.4rem;
  bottom: -0.07rem;
  position: relative;

}

.fenxiang {
  font-size: 0.35rem;
  color: #fff;
  right: 0.35rem;
  top: 0.4rem;
  position: absolute;
  z-index: 20;
  /* font-weight: bold; */
}

.remindtit {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(to right, #e97a2f, #9044d3);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* font-weight: bold; */
  /* color: red; */
}

.remind {
  width: 3.5rem;
  height: 0.8rem;
  /* background-color: rgba(0, 0, 0, 0.4); */
  text-align: center;
  position: absolute;
  top: 4.65rem;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.3rem;
  letter-spacing: 0.01rem;
}

.xiaoren {
  width: 2rem;
  top: 0.8rem;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  z-index: 2;
}

.fhbtns div {
  background-color: #2a6af2;
  border-radius: 0.5rem;

  width: 2.4rem;
  line-height: 0.9rem;
  color: rgb(255, 255, 255) !important;
}

.fhbtns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 5rem;
  height: 0.9rem;
  top: 5.55rem;
  font-size: 0.3rem;
  /* background-color: #2a6af2; */
  /* background-color: #969696; */

  /* border-radius: 0.5rem; */
  color: rgb(255, 255, 255);
}

.rightc {
  position: absolute;
  bottom: 1.3rem;
  z-index: 10;
  right: -0.4rem;
  opacity: 0.9;
}

.fhbtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.79rem;
  height: 0.9rem;
  top: 5.55rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
}

.dftit .fs {
  color: #ef842e;
  font-weight: bold;
}

.dftit {
  width: 6rem;
  text-align: center;
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.45rem;
  letter-spacing: 0.01rem;
}

.defen {
  position: absolute;
  width: 6.08rem;
  top: 2.2rem;
  left: 0;
}

.cjk {
  position: absolute;
  width: 6.08rem;
  height: 7.4rem;
  /* background-color: aqua; */
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tcts {
  /* min-width: 3.6rem; */
  white-space: nowrap;
  font-size: 0.3rem;
  background-color: rgba(8, 8, 8, 0.8);
  color: #fff;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 0.5rem;
  transform: translate(-50%, -50%);
  padding: 0.08rem 0.2rem;
  border-radius: 0.15rem;
}

.tctsv {
  white-space: nowrap;
  font-size: 0.3rem;
  background-color: rgba(8, 8, 8, 0.6);
  color: #fff;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 1.2rem;
  transform: translate(-50%, -50%);
  padding: 0.1rem 0.2rem;
  border-radius: 0.15rem;
  opacity: 0.8;
}

.tctsv .spans {
  font-weight: bold;
  color: red;
}

.xx1 {
  background-color: #a1bdf9 !important;
  color: white !important;
}

.xx2 {
  background-color: #a1bdf9 !important;
  color: white !important;
}

.xx {
  background-color: #a1bdf9 !important;
  color: white !important;
}

#xx1 {
  font-size: 0.25rem;
  /* font-weight: bold; */
  position: absolute;
  left: 0rem;
  height: 0.69rem;
  width: 2.1rem;
  background-color: #e2ebfd;
  line-height: 0.69rem;
  border-radius: 0.3rem;
}

#xx2 {
  font-size: 0.25rem;
  /* font-weight: bold; */
  position: absolute;
  right: 0rem;
  height: 0.69rem;
  width: 2.1rem;
  background-color: #e2ebfd;
  line-height: 0.69rem;
  border-radius: 0.3rem;
}

.pdt {
  width: 4.7rem;
  height: 0.69rem;
  /* background-color: #2a6af2; */
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
}

.xybtn {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 4.7rem;
  height: 0.9rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.xxm {
  position: absolute;
  left: 0.25rem;
  top: 0.2rem;
}

#xx {
  /* font-weight: bold; */
  position: relative;
  min-width: 3.71rem;
  font-size: 0.25rem;
  padding: 0.16rem 0.3rem;
  padding-left: 0.55rem;
  background-color: #e2ebfd;
  border-radius: 0.3rem;
  text-align: justify;
  margin-bottom: 0.3rem;
}

.tmm {
  width: 4.7rem;
  position: relative;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 0.3rem;
  font-weight: bold;
  text-align: justify;
}

.tmlx {
  position: absolute;
  bottom: 0.1rem;
  font-size: 0.26rem;
  right: 0;
  font-weight: bold;
  color: #595959;
}

.tmlxsa {
  position: absolute;
  bottom: 0.1rem;
  font-size: 0.26rem;
  left: 0.1rem;
  font-weight: bold;
  color: #595959;
}

.alarm {
  position: absolute;
  width: 0.45rem;
  bottom: 0.03rem;

  /* transform: translateY(-50%); */
  left: 3.46rem;
}

.alarmtit {
  position: absolute;
  bottom: 0.1rem;
  /* right: 0; */
  left: 3.9rem;
  font-size: 0.26rem;
  font-weight: bold;
}

.tmsl {
  font-size: 0.3rem;
  position: absolute;
  bottom: 0.08rem;
  font-weight: bold;
  color: #723426;
  left: 50%;
  transform: translate(-50%);
}

.tmsxk {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%);
  height: 0.53rem;
}

.btk {
  position: relative;
  height: 0.8rem;
  width: 4.7rem;
}

.nrk {
  position: relative;
  width: 4.7rem;
  /* background-color: #723426; */
  left: 50%;
  transform: translate(-50%);
}

.tmkz {
  position: relative;
  /* top: 0; */
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  /* background-color: rgb(255, 255, 255); */
  width: 6.08rem;
  /* height: 100%; */
  /* border-radius: 0.3rem; */
  max-height: 11.3rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.tmk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  width: 6.08rem;
  border-radius: 0.3rem;
  max-height: 11.3rem;
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.mengc {
  position: absolute;
  width: 100%;
  height: 100vh;
  max-height: 18rem;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
}

.gz {
  position: absolute;
  right: 0rem;
  width: 0.75rem;
  top: 2.95rem;
}

.phb {
  position: absolute;
  right: 0rem;
  width: 0.75rem;
  top: 0.8rem;
}

.dtbtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 11rem;
  height: 1.29rem;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 3.9rem;
  height: 6.4rem;
}

.tit {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.8rem;
  height: 2.5rem;
}

.bg .bgz {
  position: absolute;
  width: 100%;
  /* width:6.8rem ; */
  left: 50%;
  height: 13rem;
  top: 50%;
  transform: translate(-50%, -50%);
  /* overflow: auto; */
}

.bg {
  /* background: url(../assets/bg.png) no-repeat; */
  /* background-color: #f44b00; */
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-size: cover;
  background-position: center top;
  /* max-height: 7rem; */
  max-width: 7.5rem;
  left: 0rem;
  max-height: 18rem;
  overflow: hidden;
}
</style>