import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import html2canvas from 'html2canvas';

import Vant from 'vant';
import 'vant/lib/index.css';
import '@vant/touch-emulator';
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
Vue.use(AlloyFingerPlugin, {
    AlloyFinger
})
Vue.use(Vant);


import axios from 'axios'
// axios.defaults.baseURL = 'http://test.api.antrt.xiaoxxx.cn/h5/ANT_RT';
axios.defaults.baseURL = 'https://api.antrt.xiaoxxx.cn/h5/ANT_RT';


Vue.prototype.$axios = axios
// Vue.prototype.$cancelToken =cancelToken
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')