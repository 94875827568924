<template>
  <div class="bg">
    <transition name="van-fade">
      <div class="tcts" v-if="xsyc">{{ tsnr }}</div>
    </transition>
    <transition name="van-fade">
      <div class="mengc" v-if="xmtc"></div>
    </transition>
    <!-- 姓名输入弹窗 -->
    <transition name="van-fade">
      <div class="bgzs" v-if="xmtc&&!show_status">
        <img :src="imgsa" alt="" class="xiaoren" id="xiaoren" />
        <img src="../assets/xmk.png" alt="" class="xmk" />
        <div v-if="!department">
          <input type="text" class="inp" v-model="name" :placeholder="mztx" />
          <div class="qrbtns" @click="qrname">确认</div>
        </div>
        <div v-if="department">
          <!-- 只输入部门的时候 -->
          <div v-if="lists.department_company==1">
            <div>{{custom}}{{ bumen.length }}</div>
                <input type="text" class="inpsaz" id="inpsaz" v-model="names" :placeholder="placeh"
                v-if="custom && bumen.length == 0" />
              <input type="text" class="inpsaz" id="inpsaz" v-model="names" :placeholder="placeh" @blur="txwb"
                v-if="!custom && bumen.length != 0" />
              <input type="text" class="inpsaz" id="inpsaz" v-model="names" :placeholder="placeh" @blur="txwb"
                v-if="custom && bumen.length != 0" />
              <div class="inpsazd" @click="showPopup" v-if="shade && custom && bumen.length != 0"></div>
              <div class="inpsazd" @click="showPopup" v-if="shade && !custom && bumen.length != 0"></div>

              <input type="text" class="inpsa" v-model="name" :placeholder="mztx" />
              <div class="qrbtnsa" @click="qrname">确认</div>
          </div>
          <!-- 输入公司和部门的时候 -->
          <div v-if="lists.department_company==2">
            <!-- <div>123</div> -->
                <input type="text" class="inpsaz conpyz" id="inpsaz" v-model="names" :placeholder="placeh"
                v-if="custom && bumen.length == 0" />
              <input type="text" class="inpsaz conpyz" id="inpsaz" v-model="names" :placeholder="placeh" @blur="txwb"
                v-if="!custom && bumen.length != 0" />
              <input type="text" class="inpsaz conpyz" id="inpsaz" v-model="names" :placeholder="placeh" @blur="txwb"
                v-if="custom && bumen.length != 0" />
              <div class="inpsazd conpyzd" @click="showPopup" v-if="shade && custom && bumen.length != 0"></div>
              <div class="inpsazd conpyzd" @click="showPopup" v-if="shade && !custom && bumen.length != 0"></div>

              <input type="text" class="inpsa conpy" v-model="name" :placeholder="mztx" />
              <!-- <input type="text" class="inpsa conpy_name" v-model="companys_name" :placeholder="lists.company_department_tx" /> -->


              <textarea type="text"  class="inpsa conpy_name" v-model="companys_name" :placeholder="lists.company_department_tx" v-if="lists.company_department_tx&&lists.company_department_tx.length<=14"></textarea>

              <textarea type="text"  class="inpsa conpy_names" v-model="companys_name" :placeholder="lists.company_department_tx" v-if="lists.company_department_tx&&lists.company_department_tx.length>14"></textarea>

              <!--     -->
              <div class="qrbtnsa conpybtn" @click="qrname">确认</div>
          </div>

          
        </div>
      </div>
    </transition>


    <!-- 姓名重复提示弹窗 -->
    <transition name="van-fade">
      <div class="bgzs" v-if="show_status">
        <img :src="imgsa" alt="" class="xiaoren" id="xiaoren" />
        <img src="../assets/xmk.png" alt="" class="xmk" />
        <div>
          <!-- <input type="text" class="inp" v-model="name" :placeholder="mztx" /> -->
          <div class="qrbtns_tit">该名称已被使用，确认是您本人吗？如不是请重新输入</div>
          <div class="qrbtns_correct" @click="show_login">确认是我</div>
          <div class="qrbtns_fault" @click="show_status=false">重新输入</div>
        </div>
      </div>
    </transition>

    <transition name="van-fade">
      <div class="bgzs" v-if="code_status">
        <img :src="imgsa" alt="" class="xiaoren" id="xiaoren" />
        <img src="../assets/xmk.png" alt="" class="xmk" />
        <div>
          <input type="text" class="inp" v-model="code_content" :placeholder="code_prompt" />
          <div class="qrbtns" @click="protection">确认</div>
        </div>
      </div>
    </transition>
    <!-- 是否继续答题弹窗加蒙层 -->
    <transition name="van-fade">
      <div class="mengc" v-if="record"></div>
    </transition>
    <transition name="van-fade">
      <div class="bgzs" v-if="record">
        <img :src="imgsa" alt="" class="xiaoren" id="xiaoren" />
        <img src="../assets/xmk.png" alt="" class="xmk" />
        <div>
          <!-- <input
            type="text"
            class="inp"
            v-model="code_content"
            :placeholder="code_prompt"
          /> -->
          <div class="inpText">您还有题目没答完，快来继续答题吧！</div>
          <div class="fhbtns">
            <div @click="noAbandon">继续答题</div>
            <div @click="abandon">重新开始</div>
          </div>
          <!-- <div class="qrbtns" @click="protection">确认</div> -->
        </div>
      </div>
    </transition>

    <div class="bgz">
      <!-- <img src="../assets/tit.png" alt="" class="tit" /> -->
      <!-- <img src="../assets/logo.png" alt="" class="logo" /> -->
      <!-- 开始答题按钮 -->
      <img src="" alt="" id="btn1s" class="dtbtn" @click="ksdt" />



      <div class="right_btn_box">
        <img :src="phbimg" alt="" id="btn2s" class="phb" @click="phb" v-if="phbbtn" />
        <img src="" alt="" id="btn3s" class="gz" @click="dkgz" />
      </div>

      <div class="left_btn_box">
        <img :src="score_details_img" alt="" id="score_details" class="phb" v-show="lists.score_details == 2"
          @click="scoredetails" />
        <img :src="win_record" alt="" id="win_record" class="gz" v-show="lists.winning_record == 2" @click="records"/>
      </div>


    </div>

    <!-- 规则弹窗 -->
    <div class="gztc" v-if="gztck">
      <div class="gzk">
        <img src="../assets/gztc.png" alt="" class="gztcs" />
        <img src="../assets/gbgz.png" alt="" class="gbtc" @click="gbgz" />
        <div class="gzwak" id="gzwak" v-html="hdgz">
          <!-- <div ></div> -->
          <!-- {{ hdgz }} -->
        </div>
      </div>
    </div>
    <!-- 分数详情弹窗 -->
    <div class="gztc" v-if="scoredetails_show">
      <div class="gzk">
        <img src="../assets/fsxq.png" alt="" class="gztcs" />
        <img src="../assets/gbgz.png" alt="" class="gbtc" @click="scoredetails_show_g" />
        <div class="scoredetails_show_box">
          <div class="scoredetails_tit">
            <div style="width: 47%;" v-if="lists.ranking_time==2">答题日期</div>
            <div style="width: 28%;" v-if="lists.ranking_time==2">用时</div>
            <div style="width: 25%;" v-if="lists.ranking_time==2">分数</div>

            <div style="width: 65%;" v-if="lists.ranking_time==1">答题日期</div>
            <div style="width: 35%;" v-if="lists.ranking_time==1">分数</div>

          </div>
          <div class="scoredetails_body">
            <div v-if="scoredetails_data">
              <div class="scoredetails_body_z" v-for="(val, i) in scoredetails_data.lists" :key="i">
                <div style="width: 47%;" v-if="lists.ranking_time==2">{{ val.updated_at }}</div>
                <div style="width: 28%;" v-if="lists.ranking_time==2">{{ val.time }}s</div>
                <div style="width: 25%;" v-if="lists.ranking_time==2"><span style="color: #5689F6;font-weight: bold;">{{ val.score }}</span>分</div>
              
                <div style="width: 65%;" v-if="lists.ranking_time==1">{{ val.updated_at }}</div>
                <div style="width: 35%;" v-if="lists.ranking_time==1"><span style="color: #5689F6;font-weight: bold;">{{ val.score }}</span>分</div>
              </div>
            </div>
            <div style="font-size: 0.22rem;color: #a7a2a2;margin-top: 0.1rem;"
              v-if="scoredetails_data != null && scoredetails_data.lists && scoredetails_data.lists.length == 0">┈┈ 暂无数据 ┈┈</div>
            <div style="margin-top: 1rem;" v-show="scoredetails_data == null">
              <van-loading size="24px">加载中...</van-loading>
            </div>

          </div>
          <div class="scoredetails_btn" v-if="scoredetails_data != null">总分:{{ scoredetails_data.score }}</div>
        </div>
      </div>
    </div>

    <!-- 中奖记录弹窗 -->
    <div class="gztc" v-if="record_show">
      <div class="gzk">
        <img src="../assets/zjjl.png" alt="" class="gztcs" />
        <img src="../assets/gbgz.png" alt="" class="gbtc" @click="record_show_g" />
        <div class="scoredetails_show_box">
          <div class="scoredetails_tit">
 

            <div style="width: 50%;">奖品名称</div>
            <div style="width: 50%;">中奖时间</div>

          </div>
          <div class="record_body" :style="'height:'+(record_data&&record_data.chance<=0?'3.9rem':'3.15rem')">
            <div v-if="record_data">
              <div class="scoredetails_body_z" v-for="(val, i) in record_data.lists" :key="i" v-show="val.prize.type==1">
              
                <div style="width: 50%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ val.prize.name}}</div>
                <div style="width: 50%;">{{val.updated_at}}</div>
              </div>
            </div>
            <div style="font-size: 0.22rem;color: #a7a2a2;margin-top: 0.1rem;"
              v-if="record_data != null && record_data.lists && record_data.lists.length == 0">┈┈ 暂无数据 ┈┈</div>
            <div style="margin-top: 1rem;" v-show="record_data == null">
              <van-loading size="24px">加载中...</van-loading>
            </div>

          </div>
          <div class="record_btn" v-if="record_data != null && record_data.chance && record_data.chance>0" @click="home_luckyDraw">点击抽奖</div>
          <div class="record_tit" v-if="record_data != null">剩余{{ record_data.chance }}次抽奖机会</div>
        </div>
      </div>
    </div>


    <van-popup v-model="show" round closeable position="bottom"
      :style="{ height: lists_search ? '11rem' : '10rem' }">
      <div class="search_box" v-if="lists_search">
        <van-search v-model="value"
          :placeholder="lists.department_type?(lists.department_type==1?( lists.department_search_copy && lists.department_search_copy != '' ? lists.department_search_copy : '请输入搜索关键词'):( lists.company_search_copy && lists.company_search_copy != '' ? lists.company_search_copy : '请输入搜索关键词')):'请输入搜索关键词'"
          @input="valuebh" />
      </div>
      <div class="lieb">

        <van-index-bar :index-list="indexList" :sticky="false" @change="indexChanges">

          <div v-for="(val, i) in bumensa" :key="i">
            <van-index-anchor :index="val.pinyin" v-if="i == 0" id="xuan" :class="index_changes == val.pinyin ? 'reds' : ''" />
            <van-index-anchor :index="val.pinyin" v-if="i != 0 && bumensa[i - 1].pinyin != val.pinyin" id="xuan"
              :class="index_changes == val.pinyin ? 'reds' : ''" />
            <van-cell :title="val.name" class="xuanz" @click="section(val)" />
          </div>
          <van-index-anchor index="其他" id="xuan" v-if="custom" />
          <van-cell title="其他" class="xuanz" @click="sectionqt" v-if="custom" />
        </van-index-bar>
      </div>

    </van-popup>
    <!-- <div class="qqhc" @click="qqhc">清除缓存</div> -->
    <div class="loading" v-if="loadimg">
      <van-loading color="#82C6F4" size="22" class="loa" />
    </div>
  </div>
</template>
<script>
import { mustBeValid } from "json-schema";

export default {
  data() {
    return {

      show_status:false,

      value: '',
      index_changes: '',


      pushUrl: "",//题目的是否有解析
      record: false,//是否有答题记录

      loadimg: false,
      gztck: false, //规则弹窗
      xmtc: false, //输入姓名弹窗
      xsyc: false, //提示弹窗
      tsnr: "", //提示弹窗的文案
      name: "",
      names: "", //部门名称
      department_id: "", //部门id
      companys_name:'',//部门名称

      hdgz: "",
      hdzt: true,

      show: false, //部门弹窗的显示隐藏
      bumen: [],
      bumensa: [],
      indexList: [],

      department: false, //部门是否开启

      imgsa: "", //IQ按钮图片
      phbimg: "", //排行榜按钮图片
      phbbtn: false, //是否显示排行榜按钮
      custom: false, //用户选择部门时候是否可以自定义填写

      shade: true, //部门输入的遮罩层

      placeh: "",

      jgxz: "", //选择机构提示文案
      jgtx: "", //填写机构提示文案

      company_department_tx:'',//公司下的部门提示文案

      mztx: "", //姓名提示文案
      lists: {}, //所有的开关信息
      tsa: "",
      dsq: null,

      code_status: false, //口令输入
      code_content: "", //口令
      code_prompt: "", //口令提示文案
      // circumstances:"",//当前打开的设备

      scoredetails_show: false,//分数详情弹窗
      scoredetails_data: null,//分数详情的数据

      record_show: false,//分数详情弹窗
      record_data: null,//中奖记录详情的数据

      win_record: '',
      score_details_img: "",

      lists_search:false,//是否显示搜索框
    };
  },
  created() { },
  mounted() {
    var search = location.href.split("#")[1]; //获取参数；结果：?channelType=test
    var coid = this.hqcorid("activity_id", search);

    // console.log(coid)

    // console.log(window.localStorage.getItem("name"));

    if (coid) {
      // window.location.href =`https://h5case5.xiaoxxx.cn/202204/answer/dist/?form=index.html#/?activity_id=${coid}`
      // return
      
      if(coid==40){
      let wang=window.localStorage.getItem(`wangnames`);
      if(wang){
      }else{
        window.localStorage.removeItem("name-40");
        window.localStorage.setItem("wangnames", 'wang');
      }
      }
      let nameid = window.localStorage.getItem(`name-${coid}`);
      let nids = {};
      if (nameid != null && nameid != undefined && nameid != "") {
        nids = JSON.parse(nameid);
        // console.log(nids);
        window.localStorage.setItem("nameus", nids.names);
        window.localStorage.setItem("department_id", nids.department_id);
        window.localStorage.setItem("coid", coid);
        window.localStorage.setItem("company_id", nids.company_id);
      } else {
        window.localStorage.setItem("nameus", "");
        window.localStorage.setItem("department_id", "");
        window.localStorage.setItem("coid", coid);
        window.localStorage.setItem("company_id", '');
      }

      this.state();
    } else {
      window.localStorage.setItem("coid", null);
      this.ts("活动未开启");

      this.hdzt = false;
      this.xmtc = false;
      document.getElementById(
        "app"
      ).style.backgroundImage = `url('${require("../assets/bg.png")}')`;
      this.phbimg = require("../assets/phbbtn.png");
      this.win_record = require("../assets/phbbtn.png");
      this.score_details_img = require("../assets/phbbtn.png");

      document.getElementById("btn3s").src = require("../assets/gzbtn.png");
      document.getElementById("btn1s").src = require("../assets/dtbtn.png");
    }

    // this.hdgz
    // if(window.localStorage.getItem("coid")!=undefined && window.localStorage.getItem("coid")!='null' && window.localStorage.getItem("coid")!="")
    // this.gain()
    const style = 'color: red; background: yellow; font-size: 24px; padding: 10px; font-weight: bold;';
    console.log('%c好家伙F12你都知道，还不赶紧关了？', style);
  },
  methods: {
    home_luckyDraw(){
      // console.log(1)
      if(this.lists.draw_type==1){
        this.$router.push({
          name: `draw`,
          params: {
            type: 1,
          },
        });
        // console.log("直接抽")
      }
      if(this.lists.draw_type==2){
        this.$router.push({
          name: `draw`,
          params: {
            type: 2,
          },
        });
        // console.log("转盘抽")
      }
    },



    scoredetails_show_g(){
      this.scoredetails_show = false
      this.scoredetails_data=null
    },

    //打开分数详情弹窗
    scoredetails() {
      if (this.hdzt) {
        this.scoredetails_show = true;
        this.$axios
          .post(
            "/userLogs",
            `activity_id=${window.localStorage.getItem("coid")}&username=${window.localStorage.getItem("nameus")}`
          )
          .then((res) => {
            if (res.data.code == '0') {
              this.scoredetails_data=res.data.data
              // console.log(res);
            }

          })
      } else {
        this.ts(this.tsa);
      }
    },
    record_show_g(){
      this.record_show = false
      this.record_data=null
    },
    // 打开中将记录弹窗
    records(){
      if (this.hdzt) {
        this.record_show = true;
        this.$axios
          .post(
            "/winningRecord",
            `activity_id=${window.localStorage.getItem("coid")}&username=${window.localStorage.getItem("nameus")}`
          )
          .then((res) => {
            if (res.data.code == '0') {
              this.record_data=res.data.data
              // console.log(res);
            }

          })
      } else {
        this.ts(this.tsa);
      }
    },

    indexChanges(a) {
      this.index_changes = a
    },
    valuebh() {
      // console.log(this.value, this.bumen)
      let filterContent = []
      let wa = [];
      if (this.value != '') {
        for (var a = 0; a < this.bumen.length; a++) {
          if (this.bumen[a].name.indexOf(this.value) != -1 || this.bumen[a].pinyin.indexOf(this.value.toUpperCase()) != -1) {
            filterContent.push(this.bumen[a])
          }
        }
        if (filterContent.length != 0) {
          for (var i = 0; i < filterContent.length; i++) {
            wa.push(filterContent[i].pinyin);

          }
        }

        this.bumensa = filterContent
        let ss = this.removeDuplicate(wa);
        this.indexList = ss;

      } else {
        for (var i = 0; i < this.bumen.length; i++) {
          wa.push(this.bumen[i].pinyin);
        }
        this.bumensa = this.bumen
        let ss = this.removeDuplicate(wa);
        this.indexList = ss;
      }

      // console.log(this.bumensa, this.indexList)
    },
    // 继续上次的答题
    noAbandon() {
      let information = window.localStorage.getItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`);

      let inValue = {};

      if (information != null && information != undefined && information != "") {
        inValue = JSON.parse(information);
        inValue.frequency = inValue.frequency + 1
        window.localStorage.setItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`, JSON.stringify(inValue))
        this.$router.push({
          name: `${this.pushUrl}`,
          params: {
            na: 2,
          },
        });
        // console.log('这是第' + inValue.frequency + '次意外答题')
      } else {
        this.record = false
        this.pushUrl = ''
      }
    },
    // 放弃继续答题
    abandon() {
      this.record = false
      this.pushUrl = ''
      window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)

      if (this.lists.analysis_status == 2) {
        this.$router.push({
          name: `analysis`,
          params: {
            na: 1,
          },
        });
      } else {
        this.$router.push({
          name: `dty`,
          params: {
            na: 1,
          },
        });
      }
    },
    protection() {
      if (this.code_content == "") {
        // } else {
        this.ts(this.code_prompt);
        return;
      }

      let datas = {
        code: this.code_content,
        activity_id: window.localStorage.getItem("coid"),
      };

      this.$axios.post("/examineCode", datas).then((red) => {
        // console.log(red)
        if (red.data.code == 0) {
          this.ts("填写成功");
          window.localStorage.setItem(`kl-${window.localStorage.getItem("coid")}`, this.code_content)
          setTimeout(() => {
            this.code_status = false;
          }, 600);
        } else {
          this.ts(red.data.message);
        }
      });
    },
    qqhc() {
      window.localStorage.clear();
      location.reload();
    },
    txwb() {
      this.shade = true;
      this.placeh = this.jgxz;
    },
    sectionqt() {

      this.show = false;
      this.names = "";
      this.shade = false;
      this.placeh = this.jgtx;
      setTimeout(() => {
        document.getElementById("inpsaz").focus();
      }, 300);
      this.value = ''
      this.valuebh()
      // console.log(12);
    },
    section(val) {

      // console.log(val)
      this.department_id = val.id;
      this.names = val.name;
      this.show = false;
      this.value = ''
      this.valuebh()
    },
    showPopup() {
      this.show = true;
    },
    // 获取页面参数
    hqcorid(key, Url) {
      var str = Url;
      str = str.substring(2, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    },
    // 判断活动是否开启
    state() {
      window.localStorage.setItem("ranking_day", "");
      window.localStorage.setItem("ranking_department", "");
      window.localStorage.setItem("ranking_all", "");
      window.localStorage.setItem("ranking_score_all", "");

      this.loadimg = true
      this.$axios
        .post(
          "/judgeActivity",
          `activity_id=${window.localStorage.getItem("coid")}`
        )
        .then((red) => {
          this.loadimg = false
          // console.log(red);
          if (red.data.code == 0) {

            //       let information = window.localStorage.getItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`);
            // let inValue = {};
            // if (information != null && information != undefined && information != "") {
            //   inValue = JSON.parse(information);
            //   if(inValue.sjtm.length!=0 && inValue.pushUrl!=''){
            //     console.log('意外退出未答完');
            //     this.record=true
            //     this.pushUrl=inValue.pushUrl
            //   }
            // }
            // setTimeout(()=>{
            //   let information = window.localStorage.getItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`);
            // let inValue = {};
            // if (information != null && information != undefined && information != "") {
            // }else{
            //   this.record=false
            //     this.pushUrl=''
            // }
            // },500)



            // console.log(red.data.message);
            if (red.data.data.list) {
              this.lists = red.data.data.list;
            }

            this.hdzt = true;
            this.hdgz = red.data.data.list.explain;
            if (red.data.data.list.name_status == 1) {
              let nameid = window.localStorage.getItem(
                `name-${window.localStorage.getItem("coid")}`
              );
              if (nameid != null && nameid != undefined && nameid != "") {
              } else {
                window.localStorage.setItem("nameus", red.data.data.name);

                // if(this.department==true){
                window.localStorage.setItem("department_id", this.names);
                // }
                let ltnr = {
                  names: red.data.data.name,
                  coid: window.localStorage.getItem("coid"),
                  department_id: this.names,
                };
                window.localStorage.setItem(
                  `name-${window.localStorage.getItem("coid")}`,
                  JSON.stringify(ltnr)
                );
                this.xmtc = false;
              }
            }
            if (
              window.localStorage.getItem("nameus") != null &&
              window.localStorage.getItem("nameus") != "" &&
              window.localStorage.getItem("nameus") != undefined
            ) {
              this.xmtc = false;
            } else {
              this.xmtc = true;
            }
            if (red.data.data.list.code_status == 2) {
              let nameid = window.localStorage.getItem(
                `name-${window.localStorage.getItem("coid")}`
              );
              if (
                window.localStorage.getItem(`kl-${window.localStorage.getItem("coid")}`) != null &&
                window.localStorage.getItem(`kl-${window.localStorage.getItem("coid")}`) != "" &&
                window.localStorage.getItem(`kl-${window.localStorage.getItem("coid")}`) != undefined
              ) {
                this.code_status = false
              } else {
                this.code_status = true;
              }
              this.code_prompt = red.data.data.list.code_prompt;
            }

            if (red.data.data.list.ranking_status == 2) {
              this.phbbtn = true;
              window.localStorage.setItem(
                "ranking_day",
                red.data.data.list.ranking_day
              );
              window.localStorage.setItem(
                "ranking_department",
                red.data.data.list.ranking_department
              );
              window.localStorage.setItem(
                "ranking_all",
                red.data.data.list.ranking_all
              );
              window.localStorage.setItem(
                "ranking_score_all",
                red.data.data.list.ranking_score_all
              );

            } else {
              this.phbbtn = false;
            }
            if(red.data.data.list.department_company==1){
              
              if (red.data.data.list.else == 2) {
                this.custom = true;
              } else {
                this.custom = false;
              }
            }else{
              if (red.data.data.list.company_else == 2) {
                this.custom = true;
              } else {
                this.custom = false;
              }
            }
            
            // console.log(document.getElementById("app"))
            if (red.data.data.background == null) {
              document.getElementById(
                "app"
              ).style.backgroundImage = `url('${require("../assets/bg.png")}')`;
              this.phbimg = require("../assets/phbbtn.png");
              document.getElementById(
                "btn3s"
              ).src = require("../assets/gzbtn.png");
              document.getElementById(
                "btn1s"
              ).src = require("../assets/dtbtn.png");

              this.imgsa = require("../assets/xr.png");
              this.win_record = require("../assets/zj.png")
              this.score_details_img = require("../assets/fs.png")

            } else {
              if (red.data.data.background.background != null) {
                document.getElementById(
                  "app"
                ).style.backgroundImage = `url('${red.data.data.background.background}')`;
              } else {
                document.getElementById(
                  "app"
                ).style.backgroundImage = `url('${require("../assets/bg.png")}')`;
              }

              if (red.data.data.background.ranking != null) {
                this.phbimg = red.data.data.background.ranking;
              } else {
                this.phbimg = require("../assets/phbbtn.png");
              }

              if (red.data.data.background.rule != null) {
                document.getElementById("btn3s").src =
                  red.data.data.background.rule;
              } else {
                document.getElementById(
                  "btn3s"
                ).src = require("../assets/gzbtn.png");
              }

              if (red.data.data.background.ip_img != null) {
                document.getElementById("btn1s").src =
                  red.data.data.background.open;
              } else {
                document.getElementById(
                  "btn1s"
                ).src = require("../assets/dtbtn.png");
              }

              if (red.data.data.background.ip_img != null) {
                this.imgsa = red.data.data.background.ip_img;
              } else {
                this.imgsa = require("../assets/xr.png");
              }


              if (red.data.data.background.win_record != null) {
                this.win_record = red.data.data.background.win_record;
              } else {
                this.win_record = require("../assets/zj.png")
              }

              if (red.data.data.background.score_details_img != null) {
                this.score_details_img = red.data.data.background.score_details_img;
              } else {
                this.score_details_img = require("../assets/fs.png")
              }


            }

            if (red.data.data.list.department_type == 2) {
              this.department = true;
              if(red.data.data.list.department_company==1){
                this.bumen = red.data.data.department;
                this.bumensa = red.data.data.department;
                if(this.lists.department_search && this.lists.department_search == 2){
                  this.lists_search=true
                }
              }else{
                this.bumen = red.data.data.company;
                this.bumensa = red.data.data.company;
                if(this.lists.company_search && this.lists.company_search == 2){
                  this.lists_search=true
                }
              }
             
              let wa = [];
              for (var i = 0; i < this.bumen.length; i++) {
                // for(var a=0;a<this.indexList.length;a++){
                // if(this.indexList[a]!=this.bumen[i].pinyin){
                wa.push(this.bumen[i].pinyin);
                // console.log(2222)
                // }
                // }
              }

              // for (var i = 0; i < wa.length; i++) {
              //   for (var j = i + 1; j < wa.length; j++) {
              //     if (wa[i] == wa[j]) {
              //       wa.splice(j, 1);
              //     }
              //   }
              // }
              let ss = this.removeDuplicate(wa);
              // console.log(ss)
              this.indexList = ss;
            }
            if(red.data.data.list.department_company==1){
              this.jgxz = red.data.data.list.jgxz;
              this.jgtx = red.data.data.list.jgtx;
              // 如果么有公司或者部门的列表数据，就让显示，手动输入里面的提示文案

            }else{
              this.jgxz = red.data.data.list.company_xz
              this.jgtx = red.data.data.list.company_tx
              this.company_department_tx=red.data.data.list.company_department_tx
              // 如果么有公司或者部门的列表数据，就让显示，手动输入里面的提示文案

            }
            if(this.bumen.length==0&&this.custom){
                this.placeh=this.jgtx
              }else{
                this.placeh = this.jgxz;
              }
            this.mztx = red.data.data.list.mztx;
            
            
          } else {

            if (red.data.data.background == null) {
              document.getElementById(
                "app"
              ).style.backgroundImage = `url('${require("../assets/bg.png")}')`;
              this.phbimg = require("../assets/phbbtn.png");
              document.getElementById(
                "btn3s"
              ).src = require("../assets/gzbtn.png");
              document.getElementById(
                "btn1s"
              ).src = require("../assets/dtbtn.png");
              this.imgsa = require("../assets/xr.png");
            } else {

              if (red.data.data.background.background != null) {
                document.getElementById(
                  "app"
                ).style.backgroundImage = `url('${red.data.data.background.background}')`;
              } else {
                document.getElementById(
                  "app"
                ).style.backgroundImage = `url('${require("../assets/bg.png")}')`;
              }

              if (red.data.data.background.ranking != null) {
                this.phbimg = red.data.data.background.ranking;
              } else {
                this.phbimg = require("../assets/phbbtn.png");
              }

              if (red.data.data.background.rule != null) {
                document.getElementById("btn3s").src =
                  red.data.data.background.rule;
              } else {
                document.getElementById(
                  "btn3s"
                ).src = require("../assets/gzbtn.png");
              }

              if (red.data.data.background.ip_img != null) {
                document.getElementById("btn1s").src =
                  red.data.data.background.open;
              } else {
                document.getElementById(
                  "btn1s"
                ).src = require("../assets/dtbtn.png");
              }

              if (red.data.data.background.ip_img != null) {
                this.imgsa = red.data.data.background.ip_img;
              } else {
                this.imgsa = require("../assets/xr.png");
              }


              // document.getElementById(
              //   "app"
              // ).style.backgroundImage = `url('${red.data.data.background.background}')`;
              // this.phbimg = red.data.data.background.ranking;
              // document.getElementById("btn3s").src =
              //   red.data.data.background.rule;
              // document.getElementById("btn1s").src =
              //   red.data.data.background.open;
              // this.imgsa = red.data.data.background.ip_img;
            }
            // console.log(red.data.message)
            this.ts(red.data.message);
            this.tsa = red.data.message;
            this.hdzt = false;
            this.xmtc = false;
          }
        }).catch(err => {
          this.loadimg = false
          // alert("网络连接错误，请检查当前网络，退出重试。")
          // console.log(err)
        });
    },
    removeDuplicate(arr) {
      let len = arr.length;
      for (let i = 0; i < len; i++) {
        for (let j = i + 1; j < len; j++) {
          if (arr[i] === arr[j]) {
            arr.splice(j, 1);
            len--; // 减少循环次数提高性能
            j--; // 保证j的值自加后不变
          }
        }
      }
      return arr;
    },

    // // 获取活动规则
    // gain(){
    //     this.$axios.post("http://h5api.xiaoxxx.cn/h5/ANT_II/Admin/getExplain", `activity_id=${window.localStorage.getItem("coid")}`).then((red) => {
    //       // console.log(red)
    //       if (red.data.code == 0) {
    //        this.hdgz=red.data.data.list
    //       } else {
    //         this.ts("网络错误请退出重试");
    //       }
    //     });
    // },
    //关闭规则弹窗
    gbgz() {
      this.gztck = false;
    },
    //打开规则弹窗
    dkgz() {
      if (this.hdzt) {
        this.gztck = true;
      } else {
        this.ts(this.tsa);
      }
    },
    ksdt() {
      if (this.hdzt) {
        // this.$router.push({
        //   path: "/dty",
        // });
        let information = window.localStorage.getItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`);
        let inValue = {};
        if (information != null && information != undefined && information != "") {
          inValue = JSON.parse(information);
          if (inValue.sjtm.length != 0 && inValue.pushUrl != '') {
            if (inValue.frequency < 2) {
              // console.log('意外退出未答完');
              this.record = true
              this.pushUrl = inValue.pushUrl
              return
            } else {
              window.localStorage.removeItem(`information-${window.localStorage.getItem("coid")}-${window.localStorage.getItem("nameus")}`)
            }
          }
        }
        if (this.lists.analysis_status == 2) {
          this.$router.push({
            name: `analysis`,
            params: {
              na: 1,
            },
          });
        } else {
          this.$router.push({
            name: `dty`,
            params: {
              na: 1,
            },
          });
        }
      } else {
        this.ts(this.tsa);
      }
    },
    phb() {
      if (this.hdzt) {
        this.$router.push({
          path: "/about",
        });
      } else {
        this.ts(this.tsa);
      }
    },

    ts(i) {
      clearInterval(this.dsq);
      this.dsq = null;
      this.xsyc = true;
      this.tsnr = i;
      this.dsq = setTimeout(() => {
        this.xsyc = false;
        this.tsnr = "";
      }, 2000);
    },
    qrname() {
      if (this.name == "") {
        // } else {
        this.ts(this.mztx);
        return;
      }
      if (this.names == "" && this.department != false && (this.lists.department_company==1||this.lists.department_company==2)) {
        this.ts(this.placeh);
        return;
      }
      // if(this.lists.department_company==2&&this.companys_name==''){

      // }

      if(this.department){
        if(this.lists.department_company==2&&this.companys_name == "" ){
          this.ts(this.lists.company_department_tx);
         return;
        }
      }


      let datas = {};
      if (this.department == false) {
        datas = {
          username: this.name,
          activity_id: window.localStorage.getItem("coid"),
        };
      } else {
        // 如果开启了公司配置，那么this.names就等于公司
        if(this.lists.department_company==1){
            datas = {
             username: this.name,
             department_id: this.names,
             activity_id: window.localStorage.getItem("coid"),
           };
        }else{
          datas = {
             username: this.name,
             company_id: this.names,//公司
             department_id:this.companys_name,//部门                        
             activity_id: window.localStorage.getItem("coid"),
           };
        }

      }
      // console.log(datas)
      // return
      this.$axios.post("/submitUsername", datas).then((red) => {
        // console.log(red)
        if (red.data.code == 0) {
          this.ts("填写成功");
          setTimeout(() => {
            this.xmtc = false;
          }, 600);
          window.localStorage.setItem("nameus", this.name);
          let ltnr
          if (this.department == true) {
            if(this.lists.department_company==1){
              window.localStorage.setItem("department_id", this.names);
              ltnr = {
               names: this.name,
               coid: window.localStorage.getItem("coid"),
               department_id: this.names,
               company_id:'',
              };
            }else{
             // 如果开启了公司配置，那么this.names就等于公司
              window.localStorage.setItem("company_id", this.names);
              window.localStorage.setItem("department_id", this.companys_name);
              ltnr = {
               names: this.name,
               coid: window.localStorage.getItem("coid"),
               department_id: this.companys_name,
               company_id: this.names,
              };
            }

            
          }else{
            ltnr = {
            names: this.name,
            coid: window.localStorage.getItem("coid"),
            department_id: this.names,
            company_id:'',
           };
          }
      

          window.localStorage.setItem(
            `name-${window.localStorage.getItem("coid")}`,
            JSON.stringify(ltnr)
          );
        } else if(red.data.code==-1){
          // console.log(red.data.data)
          if(red.data.data.activity.login_status==2){
            this.show_status=true
          }else{
            this.ts("该名称已存在,请您换一个吧!");
          }
        }
      });
    },
    show_login(){
      // setTimeout(() => {
            this.show_status=false  
            this.xmtc = false;
          // }, 600);
          window.localStorage.setItem("nameus", this.name);
          let ltnr
          if (this.department == true) {
            if(this.lists.department_company==1){
              window.localStorage.setItem("department_id", this.names);
              ltnr = {
               names: this.name,
               coid: window.localStorage.getItem("coid"),
               department_id: this.names,
               company_id:'',
              };
            }else{
             // 如果开启了公司配置，那么this.names就等于公司
              window.localStorage.setItem("company_id", this.names);
              window.localStorage.setItem("department_id", this.companys_name);
              ltnr = {
               names: this.name,
               coid: window.localStorage.getItem("coid"),
               department_id: this.companys_name,
               company_id: this.names,
              };
            }

            
          }else{
            ltnr = {
            names: this.name,
            coid: window.localStorage.getItem("coid"),
            department_id: this.names,
            company_id:'',
           };
          }
      

          window.localStorage.setItem(
            `name-${window.localStorage.getItem("coid")}`,
            JSON.stringify(ltnr)
          );
    },
  },
};
</script>
<style>
.reds div {
  color: #ee0a24 !important;
}
</style>
<style scoped>
.record_tit{
  width: 5rem;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  bottom: 0rem;
  font-size: 0.22rem;
  color: #9b9696;
  letter-spacing: 0.02rem;
}
.record_btn{
  width: 4.6rem;
  height: 0.8rem;
  background-color: #ff6a26;
  color: #fff;
  font-size: 0.3rem;
  border-radius: 0.4rem;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  line-height: 0.8rem;
  text-align: center;
  /* margin-top: 0.45rem; */
  bottom: 0.45rem;
}
.scoredetails_body_z {
  display: flex;
  align-items: center;
  height: 0.5rem;
  font-size: 0.22rem;
  border-bottom: 0.02rem dashed #ECECEC;

}

.scoredetails_tit {
  position: absolute;
  height: 0.6rem;
  width: 5.6rem;
  background-color: #EDF3FF;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  color: #1E59D7;
  font-size: 0.3rem;
  font-weight: bold;
  text-align: center;
}

.scoredetails_body {
  position: absolute;
  height: 3.8rem;
  width: 5.6rem;
  /* background-color: #a8abae; */
  top: 0.7rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.record_body {
  position: absolute;
  /* height: 3.8rem; */
  width: 5.6rem;
  /* background-color: #a8abae; */
  top: 0.7rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.scoredetails_btn {
  position: absolute;
  height: 0.6rem;
  width: 5.6rem;
  background-color: #FEF7E8;
  bottom: 0;
  font-size: 0.26rem;
  font-weight: bold;
  /* line-height: 0.6rem; */
  border-radius: 0.3rem;
  color: #FF6C29;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.scoredetails_show_box {

  font-size: 0.26rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 5.6rem;
  height: 5.2rem;
  top: 1.1rem;
  /* background-color: rebeccapurple; */
}

.inpText {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.6rem;
  /* height: 0.5rem; */
  font-size: 0.4rem;
  /* background-color: aqua; */
  top: 2.81rem;
  /* border: 1px solid rgb(0, 0, 0); */
  /* border-radius: 0.5rem; */
  text-align: center;
  color: rgb(250, 106, 29);
  padding: 0.2rem 0.1rem;
  letter-spacing: 0.02rem;
}

.fhbtns div {
  background-color: #2a6af2;
  border-radius: 0.5rem;

  width: 2.4rem;
  line-height: 0.9rem;
  color: rgb(255, 255, 255) !important;
}

.fhbtns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 5rem;
  height: 0.9rem;
  top: 4.5rem;
  font-size: 0.3rem;
  /* background-color: #2a6af2; */
  /* background-color: #969696; */

  /* border-radius: 0.5rem; */
  color: rgb(255, 255, 255);
}

.loa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.loading {
  width: 0.85rem;
  height: 0.85rem;
  border-radius: 50%;
  position: absolute;
  top: 0.5rem;
  background-color: #FAFAFA;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 0.1rem 0.01rem rgba(49, 49, 49, 0.2);
}

.qqhc {
  width: 1.2rem;
  height: 0.4rem;
  position: absolute;
  top: 0;
  font-size: 0.3rem;
  background-color: rgb(248, 25, 0);
  color: #fff;
}

.xuanz:active {
  color: rgba(17, 116, 255, 0.8);
}

.lieb {
  position: absolute;
  width: 100%;
  height: 8.6rem;
  /* background-color: rebeccapurple; */
  /* height: 10%; */
  left: 0;
  /* top: 1.9rem; */
  bottom: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.search_box {
  position: absolute;
  top: 0.8rem;
  width: 100%;
  height: 1rem;
  background-color: #fff;
  z-index: 2;
  overflow: hidden;
}


.gzwak {
  /* list-style-type: disc; */
  text-align: justify;
  font-size: 0.26rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 5.18rem;
  height: 4.5rem;
  top: 1.39rem;
  overflow-y: auto;
  margin: 0 !important;

  /* background-color: #2a6af2; */
}

.gbtc {
  height: 0.53rem;
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
}

.gztcs {
  position: absolute;
  width: 6.08rem;
  top: 0;
  left: 0;
}

.gzk {
  position: absolute;
  width: 6.08rem;
  height: 6.51rem;
  /* background-color: aqua; */
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gztc {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
  /* top: 0; */
  /* bottom: 0; */
}

.tcts {
  /* min-width: 3.6rem; */
  white-space: nowrap;
  font-size: 0.3rem;
  background-color: rgba(8, 8, 8, 0.8);
  color: #fff;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
  padding: 0.08rem 0.2rem;
  border-radius: 0.15rem;
}
.conpybtn{
  height: 0.8rem !important;
  line-height: 0.8rem !important;
  top: 5rem !important;
}
.conpy{
  /* background-color: aqua;  */
  font-size: 0.25rem !important;
  height: 0.3rem !important;
  top: 4.06rem !important;
  padding: 0.18rem 0.1rem !important;
}
.conpyz{
  /* background-color: aqua;  */
  font-size: 0.25rem !important;
  height: 0.3rem !important;
  top: 2.35rem !important;
  padding: 0.18rem 0.1rem !important;

}
.conpyzd{
  /* background-color: rgba(17, 116, 255, 0.5); */
  height: 0.4rem !important;
  font-size: 0.3rem !important;
  top: 2.31rem !important;
}
.conpy_name{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4rem;
  height: 0.4rem;
  min-height:0.68rem ;
  font-size: 0.3rem;
  top: 3.4rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 0.5rem;
  text-align: center;
  letter-spacing: 0.02rem;
  font-size: 0.25rem !important;
  height: 0.3rem !important;
  top: 3.2rem !important;
  padding: 0.01rem 0.08rem !important;
  line-height: 0.68rem;
  white-space: nowrap !important;
  resize: none;
}
.conpy_names{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4rem;
  height: 0.4rem;
  min-height:0.68rem ;
  font-size: 0.3rem;
  top: 3.4rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 0.5rem;
  text-align: center;
  letter-spacing: 0.02rem;
  font-size: 0.25rem !important;
  height: 0.3rem !important;
  top: 3.2rem !important;
  padding: 0.01rem 0.08rem !important;
  /* line-height: 0.68rem; */
  /* white-space: nowrap !important;*/
  resize: none; 
}
/* .conpy_name::-webkit-scrollbar {
  width: 0px; 
  height: 0.1rem;
  position: fixed;
}
.conpy_name::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(to left, pink, blue);
  position: absolute;
}
.conpy_name::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #e1dbdb;
} */

.qrbtnsa {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.4rem;
  height: 0.9rem;
  top: 4.4rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
}

.inpsazd {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 2.5rem;
  height: 0.5rem;
  font-size: 0.3rem;
  /* background-color: aqua; */
  top: 2.37rem;
  /* border: 1px solid rgb(0, 0, 0); */
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.2rem 1rem;
  letter-spacing: 0.02rem;
}

.inpsaz {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4rem;
  height: 0.4rem;
  font-size: 0.3rem;
  /* background-color: aqua; */
  top: 2.4rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.2rem 0.1rem;
  letter-spacing: 0.02rem;
}

.inpsa {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4rem;
  height: 0.4rem;
  font-size: 0.3rem;
  /* background-color: aqua; */
  top: 3.4rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.2rem 0.1rem;
  letter-spacing: 0.02rem;
}

.inpsa::-webkit-input-placeholder {
  color: #a8abae;
  font-weight: bold;
  letter-spacing: 0.02rem;

  /* font-size: 0.22rem; */
}

.inpsa::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inpsa:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inpsa:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inpsaz::-webkit-input-placeholder {
  color: #a8abae;
  font-weight: bold;
  letter-spacing: 0.02rem;

  /* font-size: 0.22rem; */
}

.inpsaz::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inpsaz:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inpsaz:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.qrbtns_tit{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 5rem;
  height: 0.5rem;
  font-size: 0.3rem;
  font-weight: bold;
  top: 2.81rem;
  text-align: justify;
  /* padding: 0.2rem 0.1rem; */
  letter-spacing: 0.02rem;
}
.qrbtns_correct{
  position: absolute;
  left:0.7rem;
 
  /* transform: translate(-50%); */
  width: 2rem;
  height: 0.8rem;
  top: 4.4rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.8rem;
  letter-spacing: 0.02rem;
}
.qrbtns_fault{
  position: absolute;
  right: 0.7rem;
  /* transform: translate(-50%); */
  width: 2rem;
  height: 0.8rem;
  top: 4.4rem;
  font-size: 0.3rem;
  /* background-color: #2a6af2; */
  background-color: #ff6a26;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.8rem;
  letter-spacing: 0.02rem;
}
.qrbtns {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.8rem;
  height: 0.9rem;
  top: 4.4rem;
  font-size: 0.3rem;
  background-color: #2a6af2;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
}

.inp {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 4.6rem;
  height: 0.5rem;
  font-size: 0.3rem;
  /* background-color: aqua; */
  top: 2.81rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.2rem 0.1rem;
  letter-spacing: 0.02rem;
}

.inp::-webkit-input-placeholder {
  color: #a8abae;
  font-weight: bold;
  letter-spacing: 0.02rem;

  /* font-size: 0.22rem; */
}

.inp::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inp:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.inp:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8abae;
  font-weight: bold;
  /* font-size: 0.22rem; */
  letter-spacing: 0.02rem;
}

.xmk {
  position: absolute;
  width: 6.1rem;
  left: 0;
  top: 1.2rem;
}

.xiaoren {
  width: 2rem;
  top: -0.2rem;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  z-index: 2;
}

.bgzs {
  position: absolute;
  z-index: 10;
  width: 6.1rem;
  height: 6.35rem;
  /* background-color: aqua; */
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mengc {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
  /* top: 0; */
  /* bottom: 0; */
  /* z-index: 99; */

}

.gz {
  /* position: absolute; */
  /* right: 0rem; */
  width: 0.75rem;
  display: block;

  /* top: 2.95rem; */
}

.phb {
  /* position: absolute; */
  /* right: 0rem; */
  width: 0.75rem;
  display: block;
  margin-bottom: 0.15rem;
  /* display: none; */
  /* top: 0.8rem; */
}

.right_btn_box {
  top: 0.8rem;
  position: absolute;
  /* height: 1rem; */
  /* background-color: #2a6af2; */
  width: 0.75rem;
  right: 0rem;
}

.left_btn_box {
  top: 0.8rem;
  position: absolute;
  /* height: 1rem; */
  /* background-color: #2a6af2; */
  width: 0.75rem;
  left: 0rem;
}

.dtbtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 11rem;
  height: 1.29rem;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 3.9rem;
  height: 6.4rem;
}

.tit {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.8rem;
  height: 2.5rem;
}

.bg .bgz {
  position: absolute;
  width: 100%;
  /* width:6.8rem ; */
  left: 50%;
  height: 13rem;
  top: 50%;
  transform: translate(-50%, -50%);
  /* overflow: auto; */
}

.bg {
  /* background: url(../assets/bg.png) no-repeat; */
  /* background-color: #f44b00; */
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-size: cover;
  background-position: center center;
  /* max-height: 7rem; */
  max-width: 7.5rem;
  left: 0rem;
  max-height: 18rem;
  overflow: hidden;
}</style>