<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    
    <router-view v-if="circum" />
    <div v-if="!circum" class="bgtq">
      <div>
        请用 支付宝，钉钉 打开
      </div>
    </div>
  </div>
</template>
<script>
export default {
  provide() {
    return {

    };
  },
  data() {
    return {
coid:"",
circum:true,
circumstances:""
    };
  }, 
  created(){
   
  // sessionStorage.setItem("key", "");
  //   var new_element = document.createElement("script");
  //   new_element.setAttribute("type", "text/javascript");
  //   new_element.setAttribute(
  //     "src",
  //     "http://g.alicdn.com/code/lib/vConsole/3.4.0/vconsole.min.js"
  //   );
  //   document.body.appendChild(new_element);
  //   window.onload = function () {
  //     new window.VConsole();
  //   };







    this.circumstances=this.isWeixin()
  
    if(this.circumstances=="ww"){
      // alert("请用支付宝，钉钉打开")
      // console.log("请用支付宝，钉钉打开")
      this.circum=false
    }

   var search = location.href.split("#")[1]; //获取参数；结果：?channelType=test
    this.coid = this.hqcorid("activity_id", search)
  },
   mounted() {
    this.suo();
// this.share()
  },
  methods: {
           // 判断当前环境
           isWeixin() {
      let ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
      if (ua.match(/Alipay/i) == "alipay") {
        //支付宝
        // alert("支付宝");
        return "zz"
        // this.type=2
        // LA.track('zhi');
      } else if (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == "wxwork"
      ) {
        //企业微信
        // alert("微信");
        return "ww"
        // LA.track('wei');
      } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信
        // alert("微信");
        return "ww"
        // LA.track('wei');
      } else if (ua.match(/DingTalk/i) == "dingtalk") {
        //钉钉
        // alert("钉钉");
        return "dd"
        // LA.track('ding');
      } else if (ua.match(/TaurusApp/i) == "taurusapp") {
        //专有钉钉
        // alert("钉钉");
        return "dd"
        // LA.track('ding');
      } else if (window.__wxjs_environment == "miniprogram") {
        //微信小程序
        // alert("微信");
        return "ww"
        // LA.track('wei');
      } else {
        // alert("浏览器");
        return "ll"
        // LA.track('qt');
      }
      // }
    },
            // 获取页面参数
   hqcorid(key, Url) {
      var str = Url;
      str = str.substring(2, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    },
    suo() {
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) {
        deviceWidth = 7.5 * 50;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
      var lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          var now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
      if (document.getElementsByTagName("meta")["viewport"]) {
        document.getElementsByTagName("meta")["viewport"].content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
      } else {
        var meta = document.createElement("meta");
        meta.content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0";
        meta.name = "viewport";
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
    },


  },

}
</script>
<style lang="scss">
.bgtq div{
  font-size: 0.5rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top:30%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.bgtq{
  width: 100%;
  height: 100vh;

}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#app {
  //  background: url(./assets/bg.png);
   background-repeat:no-repeat;
  /* background-color: #f44b00; */
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-size: cover;
  background-position: center center;
  max-height: 18rem;
  max-width: 7.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  overflow: hidden;
}
</style>
