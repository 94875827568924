<template>
  <div class="certificate">
    <!-- <div class="opener-bg" v-if="load"> -->
        <div class="opener-box" >
            <div :class="generation?'opener':'openers'" id="wang">
              <img :src="zhengs.background" alt="" class="bg-opener">
              <div class="t1-opener t1-family" v-html="zhengs.title"></div>
              <div class="t2-opener t2-family">{{zhengs.name}}</div>
              <div class="t3-opener t3-family">{{zhengs.name_status!=''? (zhengs.name_status+'，'):''}}{{zhengs.content}}</div>
              <div class="t4-opener t4-family">{{zhengs.type==2?zhengs.explain:`${zhengs.fens}分`}}</div>
              <img :src="zhengs.logo" alt="" class="logo-operer">
              <img :src="imgs" alt="" class="settings" v-show="imgs!=''">
            </div>
            <transition name="van-fade">
            <div class="changan" v-show="imgs!=''">长按保存证书</div>
          </transition>
            <img src="../assets/gb.png" alt="" class="gbBtn" @click="close">
        </div>
    <!-- </div> -->
    <!-- <transition name="van-fade">
    <Fall v-if="fallShow"></Fall>
  </transition> -->
  </div>
</template>
<script>
import html2canvas from "html2canvas";
// import Fall from './Fall.vue'
export default {
 name:'ZhengShu',

 props:{
    zheng:{
        type:Object,
        default:{}
    },
 },
  data() {
    return {
        background:'',
        logo:'',
        zhengs:{},
        generation:false,//准备生成
        // load:false,//图片加载状态
        // fallShow:false,//下落元素
        imgs:'',
    };
  },
  created() {
    // this.zhengs=this.zheng
    // console.log(this.zheng)
    let wang=this.zheng
    if(wang.logo){
      if(wang.title.indexOf('/n')!=-1){
        let cf=wang.title.split('/n')
        // console.log(cf,6666667897)
        wang.title=`<span style="font-weight: bold;">${cf[0]}</span><br/><span style="font-weight: lighter;font-size:0.35rem">${cf[1]}</span>`
      }
      
      // wang.logo=wang.logo.replace('http://','//')
      // wang.background=wang.background.replace('http://','//')
      this.zhengs=wang
    }


    // console.log(wang)
  },
  mounted(){
    // let img=''
    // setTimeout(()=>{
      this.begin()
    // },100)
    
  },
  watch: {

  },
  methods: {
    begin(){
      // console.log(123)
      let $this=this
      
      // let image = new Image();
      //   image.crossOrigin =''
      //   image.src =$this.zhengs.background;
      //   image.onload = () => {
          // var canvas = document.createElement("canvas");
          // canvas.width = image.width;
          // canvas.height = image.height;
          // var ctx = canvas.getContext("2d");
          // ctx.drawImage(image, 0, 0, image.width, image.height);
          // $this.background=canvas.toDataURL("image/png");
          // $this.background=image.src
          // console.log('证书背景加载完了')
          // $this.fallShow=true
          $this.ready()          
        // };
    },
    ready(){
      let $this=this
    // let image1 = new Image();
    //     image1.crossOrigin =''
    //     console.log($this.zhengs,'王佳乐')
    //     image1.src =$this.zhengs.logo;
    //     image1.onload = () => {
    //       console.log(123456)
          // var canvas = document.createElement("canvas");
          // canvas.width = image1.width;
          // canvas.height = image1.height;
          // var ctx = canvas.getContext("2d");
          // ctx.drawImage(image1, 0, 0, image1.width, image1.height);
          // $this.logo=canvas.toDataURL("image/png");

          // $this.logo=image1.src
          // console.log('logo加载完了,开始执行动画')
          
          // $this.load=true
          $this.generation=true
          setTimeout(()=>{
            // 准备生成图片
            // console.log('准备生成图片')
            $this.generation=false
            // setTimeout(()=>{
            //   $this.fallShow=false
            // },1500)
            setTimeout(()=>{
              // 开始生成
              // console.log('开始生成')
              $this.qiequ()
            },100)
          },1500)   
        // };
    },
    close(){
      this.$emit('delete', '')
      // this.generation=false
      // setTimeout(()=>{
      //   this.qiequ()
      // },100)
      
    },
    //截图------------------------------------------------------------------------------------------------------
    qiequ() {
      // console.log('图片正在生成中')
      var $this = this;
      // var
      html2canvas(document.getElementById("wang"), {
        width: document.querySelector("#wang").clientWidth,
        height: document.querySelector("#wang").clientHeight-0.5,
        // height:document.querySelector("#wang").scrollHeight,//canvas高
        // width: document.querySelector("#wang").scrollWidth,//canvas宽
        backgroundColor: null, // null或transparent可将canvas背景设置为透明
        // windowWidth: document.querySelector("#wang").clientWidth,
        // windowHeight: document.querySelector("#wang").clientHeight,

        scrollY: 0,
        scrollX: 0,
        allowTaint: true,  //开启跨域
        useCORS: true,
        scale: 2, //按比例增加分辨率 (2=双倍).
        dpi: window.devicePixelRatio * 8, //设备像素比
      }).then(function (canvas) {
        $this.imgs = canvas.toDataURL('image/png');
        // console.log($this.imgs);
        // console.log('图片生成成功')
        // document.body.appendChild(canvas);
      });
    },

  },
};
</script>
<style>
.t1-family{
  /* font-family: nameH !important; */
  font-family: nameM ;
  
}
.t2-family{
  font-family: nameH !important;
  /* font-family: nameM !important; */
}
.t3-family{
  /* font-family: nameH !important; */
  font-family: nameM !important;
}
.t4-family{
  font-family: nameH !important;
  /* font-family: nameM !important; */
}
.opener{
  /* transform: scale(0.5) rotateY(0deg) translateY(-18rem); */
  /* opacity: 1; */
  transform: scale(0) rotateY(-720deg);
  animation-name: monadzsa;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
@keyframes monadzsa {
  0% {
    transform: scale(0) rotateY(-720deg);

    /* opacity: 1; */
  }

  100% {
    /* opacity: 1; */
    transform: scale(1) rotateY(0deg);
  }
}

.gbBtn{
  animation-name: gbdh;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes gbdh {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
<style  scoped>
.changan{
  position: absolute;
  top: 8.55rem;
  font-size: 0.3rem;
  color: #fff;
  text-align: center;
  width: 100%;
}
.settings{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.t4-opener{
  position: absolute;
  top: 4.8rem;
  width: 100%;
  text-align: center;
  font-size: 0.6rem;
  /* font-weight: bold; */
  color: #e57716;

}
.t3-opener{
  position: absolute;
  top: 3.5rem;
  width: 4.8rem;
  left: calc(50% - 2.4rem);
  text-align: justify;
  font-size: 0.3rem;
  /* font-weight: bold; */
  color: rgb(0, 0, 0);

}
.t2-opener{
  position: absolute;
  top: 2.05rem;
  width: 5.5rem;
  left: calc(50% - 2.75rem);
  /* background-color: #3471f3; */
  text-align: center;
  font-size: 0.6rem;
  /* font-weight: bold; */
  color: #3471f3;

}
.t1-opener{
  position: absolute;
  top: 0.8rem;
  width: 5.5rem;
  left: calc(50% - 2.75rem);
  text-align: center;
  font-size: 0.4rem;
  /* font-weight: bold; */
  color: #2e54a6;
}
.logo-operer{
  width: 2rem;
  position: absolute;
  /* top: 7rem; */
  bottom:0.8rem;
  left: calc(50% - 1rem);
  
}
.bg-opener{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
.gbBtn{
    width: 0.5rem;
    position: absolute;
    bottom: -0.2rem;
    transform: translate(-50%);
  left:50%;
}
.opener {
  position: absolute;
  top: 0rem;
  left: 0;
  background-color: aliceblue;
  width: 6.38rem;
  height: 8.4rem;
  overflow: hidden;
  /* border-radius: 0.3rem; */
}
.openers {
  position: absolute;
  top: 0rem;
  left: 0;
  background-color: aliceblue;
  width: 6.38rem;
  height: 8.4rem;
  overflow: hidden;
  /* border-radius: 0.3rem; */
}
.opener-box {
  position: absolute;
  /* top: 1.6rem; */
  transform: translate(-50%,-50%);
top: 45%;
left: 50%;
  /* transform: translate(-50%); */
  width: 6.38rem;
  height: 9.6rem;
}
.certificate{
    position: absolute;
    width: 100%;
    height: 100vh;
    max-height: 18rem;
    min-height: 13rem;
    max-width: 7.5rem;
    left: 0rem;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}
</style>