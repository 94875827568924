import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import dty from '../views/dty.vue'
import cs from '../views/cs.vue'
import analysis from '../views/analysis.vue'
import draw from '../views/draw.vue'




Vue.use(VueRouter)

const routes = [{
    path: '/draw',
    name: 'draw',
    component: draw
},{
    path: '/analysis',
    name: 'analysis',
    component: analysis
},{
    path: '/dty',
    name: 'dty',
    component: dty
}, {
    path: '/',
    name: 'Home',
    component: Home
}, 
{
    path: '/cs',
    name: 'cs',
    component: cs
},{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/About.vue')
}]

const router = new VueRouter({
    routes
})

export default router