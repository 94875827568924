<template>
  <div class="bg">
    <div class="bgz">
      <!-- <img src="../assets/tit.png" alt="" class="tit" />
      <img src="../assets/logo.png" alt="" class="logo" /> -->
      <!-- <img src="../assets/dtbtn.png" alt="" class="dtbtn" /> -->
      <!-- <img src="../assets/phbbtn.png" alt="" class="phb" />
      <img src="../assets/gzbtn.png" alt="" class="gz" /> -->
    </div>
    <div class="mengc">
      <!-- 提示框 -->
      <transition name="van-fade">
        <div class="tcts" v-if="xsyc">{{ tsnr }}</div>
      </transition>

      <!-- 抽奖大转盘 -->
      <div class="turntable" v-if="typp == 2">
        <LuckyWheel
          ref="myLucky"
          width="6rem"
          height="6rem"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
        />
      </div>

      <!-- 中奖信息填写 -->
      <div class="information" v-if="typp == 1">
        <div class="infrom">
          <div class="title">
            <img src="../assets/z.png" alt="" />
            <div>恭喜你抽中</div>
            <img src="../assets/y.png" alt="" />
          </div>
          <div class="imgk">
            <img :src="souvenir.img" alt="" />
          </div>
          <div class="text">{{ souvenir.name }}</div>
          <!-- <div class="radio" v-if="grant_type!=3">
              <div class="tess" v-if="grant_type==1">线上邮寄</div>
              <div class="tess" v-if="grant_type==2">线下领取</div>
            </div> -->
          <van-radio-group
            v-model="radio"
            direction="horizontal"
            class="radio"
            v-if="grant_type == 3"
          >
            <van-radio name="2" class="tess" v-if="grant_type == 3"
              >线下领取</van-radio
            >
            <van-radio name="1" class="tess" v-if="grant_type == 3"
              >线上邮寄</van-radio
            >
          </van-radio-group>
          <!-- 线上邮寄 -->
          <div v-if="radio == '1'">
            <div class="hint" v-if="prompt.online_prompt">{{prompt.online_prompt}}</div>
            <div class="content">
              <div class="titname">收货人</div>
              <input
                type="text"
                v-model="sname"
                class="inpname"
                placeholder="请输入姓名"
              />
            </div>
            <div class="content">
              <div class="titname">手机号</div>
              <input
                type="text"
                v-model="phone"
                class="inpname"
                placeholder="请输入手机号"
              />
            </div>
            <div class="content">
              <div class="titname">地&nbsp;&nbsp;&nbsp;&nbsp;区</div>
              <input
                readonly="readonly"
                v-model="region"
                type="text"
                class="inpname"
                @click="show"
                placeholder="点击选择省市区"
              />
            </div>
            <div class="content">
              <div class="titname">地&nbsp;&nbsp;&nbsp;&nbsp;址</div>
              <input
                type="text"
                v-model="location"
                class="inpname"
                placeholder="请输入详细地址"
              />
            </div>
            <div class="btn" @click="onLine" v-if="submit">提交</div>
            <div class="btn" v-if="!submit">提交</div>

          </div>
          <!-- 线下领取 -->
          <div v-if="radio == '2'">
            <div class="hint" v-if="prompt.offline_prompt">{{prompt.offline_prompt}}</div>
            <div class="content">
              <div class="titname" v-if="prompt.offline_mark">{{prompt.offline_mark}}</div>
              <input
                type="text"
                v-model="hname"
                class="inpname"
                :placeholder="prompt.offline_mark_prompt"
                v-if="prompt.offline_mark_prompt"
              />
            </div>
            <div class="btn" @click="offline" v-if="submit">提交</div>
            <div class="btn"  v-if="!submit">提交</div>
          </div>
        </div>
      </div>
      <!-- 未中奖弹窗 -->
      <div class="losings" v-if="typp==3">
        <div class="losingText">很遗憾，没抽中</div>
        <div class="wbtn" @click="losings">确定</div>
      </div>
         <!-- 奖品抽完弹窗 -->
         <div class="losings" v-if="typp==4">
        <div class="losingTexts">礼物已经领取完，谢谢你的参与</div>
        <div class="wbtn" @click="losings">确定</div>
      </div>
      <!-- 地区板块 -->
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      
    </div>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {

      dds:null,

      xsyc: false, //提示弹窗
      tsnr: "", //提示弹窗的文案
      radio: "2", //线下领取或线上邮寄
      areaList: areaList, // 数据格式见 Area 组件文档

      showArea: false, //显示地区选择

      //线下领取
      hname: "", //花名

      // 线上邮寄
      sname: "", //收货人姓名
      phone: "", //手机号
      region: "", //地区
      location: "", //详细地址

      blocks: [
        {
          imgs: [
            {
              src: require("../assets/bga.png"),
              width: "100%",
              top: "0",
              rotate: true,
            },
          ],
          padding: "0.42rem",
        },
      ],
      prizes: [
        // { imgs:[{src:require("../assets/xr.png"),width:"30%",top:'30%',}], fonts: [{ text: '浪费的说法',fontSize:"0.25rem", top: '8%' }],  background: '#ffffff' },
        // { imgs:[{src:require("../assets/xr.png"),width:"30%",top:'30%',}], fonts: [{ text: '浪费的说法',fontSize:"0.25rem", top: '8%' }],  background: '#fff8e3' },
        // { imgs:[{src:require("../assets/xr.png"),width:"30%",top:'30%',}], fonts: [{ text: '浪费的说法',fontSize:"0.25rem", top: '8%' }],  background: '#ffffff' },
        // { imgs:[{src:require("../assets/xr.png"),width:"30%",top:'30%',}], fonts: [{ text: '浪费的说法',fontSize:"0.25rem", top: '8%' }],  background: '#fff8e3' },
        // { imgs:[{src:require("../assets/xr.png"),width:"30%",top:'30%',}], fonts: [{ text: '浪费的说法',fontSize:"0.25rem", top: '8%' }],  background: '#ffffff' },
        // { imgs:[{src:require("../assets/xr.png"),width:"30%",top:'30%',}], fonts: [{ text: '浪费的说法',fontSize:"0.25rem", top: '8%' }],  background: '#fff8e3' },
      ],
      buttons: [
        {
          radius: "27%",
          // background: '#8a9bf3',
          // pointer: true,
          // fonts: [{ text: '开始', top: '-10px' }],
          imgs: [
            {
              src: require("../assets/cj.png"),
              width: "1.8rem",
              top: "-1.18rem",
            },
          ],
        },
      ],

      zdid: null,

      prize: 0, //是否抽中
      typp: 0, //抽奖方式
      grant_type: 0, //填写方式
      souvenir: {}, //礼品

      log_id:null,//奖品的id
      
      
      submit:true,//提交按钮
      prompt:{},//文案
      // losing:false,//未中奖
    };
  },
  watch: {},
  created() {
    var sc = this.$route.params.type;
    if (sc != undefined) {
      // console.log(sc);
      if (sc == "2") {
        
        this.lottery();
        // console.log("转盘抽");
      } else if (sc == "1") {
        // this.typp=1
        this.directDraw();
        // this.lottery()
        // console.log("直接抽");
      }
    } else {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    }
  },
  mounted() {
    if (
      window.localStorage.getItem("coid") == "null" ||
      window.localStorage.getItem("coid") == "" ||
      window.localStorage.getItem("coid") == undefined
    ) {
      this.$router.push({
        path: `/`,
      });
      return;
    }
    // console.log(window.localStorage.getItem("name"))
    if (
      window.localStorage.getItem("nameus") == null ||
      window.localStorage.getItem("nameus") == "" ||
      window.localStorage.getItem("nameus") == undefined
    ) {
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    }
  },
  methods: {
    losings(){
      this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
    },
    // 邮寄信息提交
    onLine(){
      if(this.sname==""){
        this.ts("收货人未填写")        
        return
      }
      if(this.phone==""){
        this.ts("手机号未填写")        
        return
      }
      if(this.region==""){
        this.ts("地区未选择")        
        return
      }
      if(this.location==""){
        this.ts("详细地址未填写")        
        return
      }
      this.submit=false
      this.$axios
        .post(
          "/drawAddress",
          `log_id=${this.log_id}&name=${this.sname}&tel=${this.phone}&address=${this.region+" "+this.location}&grant_type=1`
        )
        .then((red) => {
          // console.log(red);
          if (red.data.code == 0) {
            
            this.ts("提交成功") 
            setTimeout(()=>{
              this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
            },1500)

          } else {
      this.submit=true
            this.ts("提交失败,请重试")        
          }
        });
    },
    // 线下信息提交
    offline(){
      if(this.hname==""){
        this.ts("花名未填写")        
        return
      }
      this.submit=false
      this.$axios
        .post(
          "/drawAddress",
          `log_id=${this.log_id}&name=${this.hname}&grant_type=2`
        )
        .then((red) => {
          // console.log(red);
          if (red.data.code == 0) {
            this.ts("提交成功") 
            setTimeout(()=>{
              this.$router.push({
        path: `/?activity_id=${window.localStorage.getItem("coid")}`,
      });
            },1500)
          } else {
      this.submit=true
            this.ts("提交失败,请重试")        
          }
        });

    },


    // 直接抽奖
    directDraw() {
      this.$axios
        .post(
          "/draw",
          `username=${window.localStorage.getItem(
            "nameus"
          )}&activity_id=${window.localStorage.getItem(
            "coid"
          )}&department_id=${window.localStorage.getItem("department_id")}&company_id=${window.localStorage.getItem("company_id")}`
        )
        .then((red) => {
          // console.log(red);
          if (red.data.code == 0) {
            if (red.data.data.type == 1) {
              this.typp = 1;
              this.souvenir = red.data.data.data;
              this.grant_type = red.data.data.grant_type;
              this.log_id=red.data.data.log_id
            this.prompt=red.data.data.prompt

              if (this.grant_type == 2 || this.grant_type == 3) {
                this.radio = "2";
              }
              if (this.grant_type == 1) {
                this.radio = "1";
              }
            } else if (red.data.data.type != 1) {
              // console.log("未抽中");
              // this.losing=true
        this.typp=3
            }
          } else {
            this.$router.push({
              path: `/?activity_id=${window.localStorage.getItem("coid")}`,
            });
            return;
          }
        });
    },
    //调用转盘抽奖
    lottery() {
      this.$axios
        .post(
          "/getDrawList",
          `username=${window.localStorage.getItem(
            "nameus"
          )}&activity_id=${window.localStorage.getItem(
            "coid"
          )}&department_id=${window.localStorage.getItem("department_id")}&company_id=${window.localStorage.getItem("company_id")}`
        )
        .then((red) => {
          // console.log(red);
          if (red.data.code == 0) {
            this.typp = 2;
            let a = red.data.data.list;
            this.prizes = [
              {
                imgs: [{ src: a[0].img, width: "32%", top: "30%" }],
                fonts: [
                  {
                    text: a[0].type == 1 ? a[0].name : "",
                    fontSize: "0.25rem",
                    top: "8%",
                  },
                ],
                background: "#ffffff",
              },
              {
                imgs: [{ src: a[1].img, width: "32%", top: "30%" }],
                fonts: [
                  {
                    text: a[1].type == 1 ? a[1].name : "",
                    fontSize: "0.25rem",
                    top: "8%",
                  },
                ],
                background: "#fff8e3",
              },
              {
                imgs: [{ src: a[2].img, width: "32%", top: "30%" }],
                fonts: [
                  {
                    text: a[2].type == 1 ? a[2].name : "",
                    fontSize: "0.25rem",
                    top: "8%",
                  },
                ],
                background: "#ffffff",
              },
              {
                imgs: [{ src: a[3].img, width: "32%", top: "30%" }],
                fonts: [
                  {
                    text: a[3].type == 1 ? a[3].name : "",
                    fontSize: "0.25rem",
                    top: "8%",
                  },
                ],
                background: "#fff8e3",
              },
              {
                imgs: [{ src: a[4].img, width: "32%", top: "30%" }],
                fonts: [
                  {
                    text: a[4].type == 1 ? a[4].name : "",
                    fontSize: "0.25rem",
                    top: "8%",
                  },
                ],
                background: "#ffffff",
              },
              {
                imgs: [{ src: a[5].img, width: "32%", top: "30%" }],
                fonts: [
                  {
                    text: a[5].type == 1 ? a[5].name : "",
                    fontSize: "0.25rem",
                    top: "8%",
                  },
                ],
                background: "#fff8e3",
              },
            ];
            let s = red.data.data.drawRe.original;
            if (s.code != 0) {
              this.$router.push({
                path: `/?activity_id=${window.localStorage.getItem("coid")}`,
              });
              return;
            }
            this.souvenir = red.data.data.drawRe.original.data.data;
            if(!s.data.data.img){
            this.typp = 4;
            return
            }
            for (var i = 0; i < a.length; i++) {
              if (a[i].img == s.data.data.img) {
                this.zdid = i;
              }
            }
            this.grant_type = red.data.data.grant_type;
            this.log_id=red.data.data.drawRe.original.data.log_id
            this.prize = red.data.data.drawRe.original.data.type;
            this.prompt=red.data.data.drawRe.original.data.prompt
          } else {
            // this.ts("网络异常成绩提交失败");
          }
        });
    },
    show() {
      this.showArea = true;
    },
    onConfirm(values) {
      this.region = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      // console.log(this.value);
      this.showArea = false;
    },
    ts(i) {
      clearInterval(this.dds);
      this.dds=null
      this.xsyc = true;
      this.tsnr = i;
      this.dds=setTimeout(() => {
        this.xsyc = false;
        this.tsnr = "";
      }, 2000);
    },

    // 点击抽奖按钮会触发star回调
    startCallback() {
      if (this.zdid != null) {
        // 调用抽奖组件的play方法开始游戏
        this.$refs.myLucky.play();
        // 模拟调用接口异步抽奖
        setTimeout(() => {
          // 假设后端返回的中奖索引是0
          const index = 5;
          // 调用stop停止旋转并传递中奖索引
          this.$refs.myLucky.stop(this.zdid);
        }, 3000);
      }
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      // console.log(prize);
      if (this.prize == 1) {
        this.typp = 1;
        // console.log(this.grant_type);
        if (this.grant_type == 2 || this.grant_type == 3) {
          this.radio = "2";
        }
        if (this.grant_type == 1) {
          this.radio = "1";
        }
      } else {
        // console.log("未抽中");
        // this.losing=true
      this.typp=3

      }

      // this.$refs.myLucky.init()//抽奖后重置
    },
  },
};
</script>
<style scoped>
.wbtn{
  width: 4.6rem;
  height: 0.8rem;
  background-color: #ff6a26;
  color: #fff;
  font-size: 0.3rem;
  border-radius: 0.4rem;
  left: 50%;
  position: relative;
  transform: translate(-50%);
  line-height: 0.8rem;
  text-align: center;
  margin-top: 0.45rem;
  margin-bottom: 0.4rem;
}
.losingText{
  align-items: center;
  font-size: 0.4rem;
  font-weight: bold;
  margin-top: 0.4rem;
}
.losingTexts{
  align-items: center;
  font-size: 0.35rem;
  font-weight: bold;
  margin-top: 0.4rem;
}
.losings{
 position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 5.8rem;
  border-radius: 0.3rem;
  /* min-height: 1rem; */
  border: 0.01rem solid #fff;
  text-align: center;
}
.hint {
  font-size: 0.22rem;
  margin-top: 0.2rem;
  color: #faad14;
}
.btn {
  width: 4.6rem;
  height: 0.8rem;
  background-color: #ff6a26;
  color: #fff;
  font-size: 0.3rem;
  border-radius: 0.4rem;
  left: 50%;
  position: relative;
  transform: translate(-50%);
  line-height: 0.8rem;
  text-align: center;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
}
.inpname {
  position: absolute;
  left: 1.2rem;
  text-align: justify;
  width: 3.6rem;
  height: 0.55rem;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  /* line-height: 0.6rem; */
  font-size: 0.25rem;
}
.titname {
  position: absolute;
  left: 0;
  text-align: center;
  width: 1.2rem;
  height: 0.6rem;
  line-height: 0.6rem;
  font-size: 0.25rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
  /* background-color: rebeccapurple; */
}
.content {
  position: relative;
  width: 5rem;
  height: 0.6rem;
  border: 0.01rem solid rgb(216, 211, 211);
  border-radius: 0.1rem;
  left: 50%;
  transform: translate(-50%);
  margin-top: 0.1rem;
  margin-bottom: 0.15rem;
}
.tess {
  font-size: 0.25rem;
  position: relative;
}
.radio {
  position: relative;
  justify-content: center;
}
.text {
  width: 100%;
  font-size: 0.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.3rem;
}
.imgk img {
  /* width: 100%; */
  /* object-fit:cover; */

  height: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  /* top: 0; */
}
.imgk {
  max-width: 5rem;
  overflow: hidden;
  height: 2.8rem;
  /* background-color: rgb(176, 125, 125); */
  border-radius: 0.1rem;
  /* overflow: hidden; */
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 0.32rem;
  margin-bottom: 0.2rem;
}
.title img {
  height: 0.3rem;
}
.title div {
  margin: 0 0.35rem;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  font-weight: bold;
  margin-top: 0.4rem;
}
.infrom {
  position: relative;
  background-color: #fff;
  width: 5.8rem;
  border-radius: 0.3rem;
  /* min-height: 8rem; */
  border: 0.01rem solid #fff;
  text-align: center;
}
.information {
  position: absolute;
  width: 5.8rem;
  height: 10.7rem;
  /* background-color: rebeccapurple; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.turntable {
  position: absolute;
  width: 6rem;
  height: 6rem;
  /* background-color: rgb(255, 255, 255); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tcts {
  /* min-width: 3.6rem; */
  white-space: nowrap;
  font-size: 0.3rem;
  background-color: rgba(8, 8, 8, 0.8);
  color: #fff;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 0.5rem;
  transform: translate(-50%, -50%);
  padding: 0.08rem 0.2rem;
  border-radius: 0.15rem;
}
.mengc {
  position: absolute;
  width: 100%;
  height: 100%;
  
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
}
.bg .bgz {
  position: absolute;
  width: 100%;
  /* width:6.8rem ; */
  left: 50%;
  height: 13rem;
  top: 50%;
  transform: translate(-50%, -50%);
  /* overflow: auto; */
}
.bg {
  /* background: url(../assets/bg.png) no-repeat; */
  /* background-color: #f44b00; */
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-size: cover;
  background-position: center top;
  /* max-height: 7rem; */
  max-width: 7.5rem;
  left: 0rem;
  max-height: 18rem;
  overflow: hidden;
}
</style>